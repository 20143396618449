import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircle";

import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import {
  emailRegex,
  BackendBaseApi,
  PRAVESH_API,
} from "../../../Constants/constants";
import logo from "../../../Assets/Images/png/getwork-logo.png";
import axios from "axios";
import { useAlert } from "react-alert";
import { alpha, styled } from "@mui/material/styles";
import { CustomTextField } from "../../Common/InputText/InputField";
import fetchApi from "../../../Apis/fetchApi";

export default function NewPassword({
  setStep,
  email,
  setEmail,
  responseGoogle,
  setOtpEditStep,
  password,
  setPassword,
  otpForgetPwd,
}) {
  // const classes = useStyles();
  const [emailError, setEmailError] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const [errorValues, setErrorValues] = React.useState({
    password: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = () => {
    let isError = false;
    let passwordError = "";
    if (!values?.password || values?.password.length < 6) {
      passwordError = "Password length should be atleast 6 character";
      isError = true;
    }
    setErrorValues({
      password: passwordError,
    });

    return isError ? false : true;
  };

  const helper = (data) => {
    if (data.data.code === 3) setStep(11);
  };

  function resetPassword() {
    fetchApi({
      url: `${PRAVESH_API}company_saas/login/change_password/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        password: values?.password,
        email: email,
        otp: parseInt(otpForgetPwd),
      }),
      setLoading: setLoading,
      callBackFn: helper,
      loginFlow: true,
      alert: alert,
    });
  }

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Typography variant="h4" style={{ fontWeight: "500" }}>
          Reset Password
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              width: "100%",
            }}
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                fontSize: "14px",
                color: errorValues?.password ? "#DC3545" : "#7e7e7e",
                paddingTop: "1.2px",
              }}
            >
              Create New Password
            </InputLabel>
            <OutlinedInput
              style={{
                borderRadius: "8px",
                height: "40px",
              }}
              // inputProps={{ style: { fontSize: 14 } }}
              // InputLabelProps={{ style: { fontSize: 14 } }}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values?.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              error={errorValues?.password ? true : false}
              helperText={errorValues?.password ? errorValues?.password : ""}
            />
            <FormHelperText
              style={{
                fontSize: "12px",
                color: "#DC3545",
              }}
              id="standard-weight-helper-text"
              //style={{errorValues?.password && errorPassword}}
            >
              {errorValues?.password ? errorValues?.password : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              width: "100%",
            }}
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                fontSize: "14px",
                color: "#7e7e7e",
                paddingTop: "1.2px",
              }}
            >
              New Password
            </InputLabel>
            <OutlinedInput
              style={{
               borderRadius: "8px",
                height: "40px",
              }}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={password?.resetPassword}
              onChange={(e) => {
                setPassword({
                  ...password,
                  resetPassword: e.target.value,
                });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
            />
            <FormHelperText
              style={{
                fontSize: "12px",
              }}
              id="standard-weight-helper-text"
              // style={{errorValues?password && errorPassword}}
            >
              {errorValues?.password ? errorValues?.password : ""}
            </FormHelperText>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <Button
            color="primary"
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "8px",
              display: "flex",
              fontWeight: "400",
              fontSize: "15px",
            }}
            variant="contained"
            onClick={() => {
              if (validate()) {
                resetPassword();
              }
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Confirm"
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
