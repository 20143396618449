import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Typography,
  Autocomplete,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CustomTextField } from "../../Common/InputText/InputField";
import Close from "@mui/icons-material/Close";
import { Male, Female, Transgender } from "@mui/icons-material";
import LoginPublicJobApply from "../../Section/LoginRightSection/LoginPubicJobApply";
import EnterOtpApplyJobs from "../../Section/LoginRightSection/EnterOtpApplyJob";
import RegisterCompany from "./RegisterCompany";
import RegisterApplyJob from "../../Section/LoginRightSection/RegisterApplyJob";
import RegisterWorkHistory from "../../Section/LoginRightSection/RegisterWorkHistory";
import { NIYUKTI_API, PRAVESH_API } from "../../../Constants/constants";
import fetchApi from "../../../Apis/fetchApi";
import { useAlert } from "react-alert";
import EnterEmailJob from "../../Section/LoginRightSection/EnterEmailJob";
import ConfirmPassword from "../../Section/LoginRightSection/ConfirmPassword";
import NewPassword from "../../Section/LoginRightSection/NewPassword";
import EnterEmailOtp from "../../Section/LoginRightSection/EnterEmailOtp";
import RecoverEmailPassword from "../../Section/LoginRightSection/RecoverEmailPassword";
import { useHistory } from "react-router-dom";

export default function PublicJobApplyDialog({
  open,
  handleClose,
  currentStep,
  handleClickOpen,
  setEmail,
  email,
  setPhoneNo,
  phoneNo,
  job_id,
  handleClickOpenSuccesfully,
}) {
  const theme = useTheme();
  const alert = useAlert();
  const history = useHistory();
  // const [fullWidth, setFullWidth] = React.useState("");

  const [step, setStep] = useState(null);
  const [applied, setApplied] = useState(false);
  const [otpForgetPwd, setOtpForgetPwd] = useState("");
  useEffect(() => {
    //console.log("DIalgo", currentStep);
    setStep(currentStep);
  }, [open, currentStep]);

  //   const getDetails = (token) => {
  //     Axios.get(`${PRAVESH_API}api/profile/details`, {
  //       headers: {
  //         Authorization: "Token " + token,
  //       },
  //       withCredentials: true,
  //     })
  //       .then((res) => {
  //         localStorage.setItem("gw_token", token);
  //         localStorage.setItem("is_pref_filled", res.data.is_pref_filled);
  //         localStorage.setItem(
  //           "is_student_resume_uploaded",
  //           res.data.is_student_resume_uploaded
  //         );
  //         localStorage.setItem(
  //           "profile_complete",
  //           res.data.user_type[0].profile_complete
  //         );
  //         localStorage.setItem(
  //           "student_profile_check",
  //           res.data.student_profile_check ? true : false
  //         );
  //         localStorage.setItem("user_type", res.data.user_type[0].main_user);
  //         localStorage.setItem("user_id", res.data.id);
  //         localStorage.setItem("user_type_id", res.data.user_type[0].type);
  //         localStorage.setItem("profile_picture", res.data.profile_picture[0]);
  //         localStorage.setItem("first_name", res.data.first_name);
  //         localStorage.setItem("last_name", res.data.last_name);
  //         localStorage.setItem("email", res.data.email);
  //         const temp = {
  //           gw_token: token,
  //           is_pref_filled: res.data.is_pref_filled,
  //           is_student_resume_uploaded: res.data.is_student_resume_uploaded,
  //           profile_complete: res.data.user_type[0].profile_complete,
  //           student_profile_check: res.data.student_profile_check ? true : false,
  //           user_type: res.data.user_type[0].main_user,
  //           user_id: res.data.id,
  //           user_type_id: res.data.user_type[0].type,
  //           profile_picture: res.data.profile_picture[0],
  //           first_name: res.data.first_name,
  //           last_name: res.data.last_name,
  //           email: res.data.email,
  //         };
  //         value.setUserDetails(temp);
  //         if (
  //           OnCampus &&
  //           localStorage.getItem("student_profile_check") === "false"
  //         ) {
  //           {
  //             changeStep(5);
  //           }
  //         } else if (apply) {
  //           changeStep(3);
  //         } else {
  //           handleClose();
  //         }

  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         alert.error("some error occurred on our side");
  //         changeStep(0);
  //       });
  //   };

  const getUser = (token) => {
    const userhelper = (data) => {
      sessionStorage.setItem("gw_token", token);
      sessionStorage.setItem("is_pref_filled", data.is_pref_filled);
      sessionStorage.setItem(
        "is_student_resume_uploaded",
        data.is_student_resume_uploaded
      );
      sessionStorage.setItem(
        "profile_complete",
        data.user_type[0].profile_complete
      );
      sessionStorage.setItem(
        "student_profile_check",
        data.student_profile_check ? true : false
      );
      sessionStorage.setItem("user_type", data.user_type[0].main_user);
      sessionStorage.setItem("user_id", data.id);
      sessionStorage.setItem("user_type_id", data.user_type[0].type);
      sessionStorage.setItem("profile_picture", data.profile_picture[0]);
      sessionStorage.setItem("first_name", data.first_name);
      sessionStorage.setItem("last_name", data.last_name);
      sessionStorage.setItem("email", data.email);

      if (
        sessionStorage.getItem("is_student_resume_uploaded") === "true" &&
        job_id !== null
      ) {
        applyToJob(token);
        // setApplyClicked(true);
      } else {
        setStep(3);
      }
    };

    const headers = JSON.stringify({
      //headers: { Authorization: `Token ${token}` },
      withCredentials: true,
    });
    fetchApi({
      url: `${PRAVESH_API}api/profile/details/`,
      headers: headers,
      //alert: alert,
      setResponse: userhelper,
      // callBackFn: helper,
      //loginFlow: true,
      //errorCallback: errorHelper,
    });
  };

  const similarJobsHelper = (data) => {
    //console.log("Similar JObs", data);
    sessionStorage.setItem("similarJobs", JSON.stringify(data?.data?.data));
    handleClickOpenSuccesfully();
  };

  const getSimilarJobs = (token) => {
    const headers = JSON.stringify({
      //headers: { Authorization: `Token ${token}` },
      withCredentials: true,
    });
    fetchApi({
      url: `${NIYUKTI_API}job/similar_jobs/?count=3`,
      method: "post",
      headers: headers,
      body: JSON.stringify({ job_id: parseInt(job_id) }),
      // alert: alert,
      setResponse: similarJobsHelper,
    });
  };

  const applyToJob = (token) => {
    const applyJobHelper = () => {
      //console.log("after Aplly", data);
      getSimilarJobs(token);
      sessionStorage.setItem("jobApplied", "true");
      handleClose();
      // handleClickOpenSuccesfully();
    };

    const headers = JSON.stringify({
      //headers: { Authorization: `Token ${token}` },
      withCredentials: true,
    });
    const body = {
      job_id: parseInt(job_id),
      user_id: sessionStorage.getItem("user_id"),
      round: 1,
      status: 1,
      feedback: 1,
      college_id: 0,
      profile_complete_percentage: sessionStorage.getItem("profile_complete"),
    };

    fetchApi({
      url: `${NIYUKTI_API}job/student/apply/`,
      method: "post",
      headers: headers,
      body: JSON.stringify(body),
      alert: alert,
      setResponse: applyJobHelper,
    });
  };

  //   useEffect(() => {
  //     if (jobApply) {
  //       setApplyButtonLoader(true);
  //       Axios.post(
  //         `${NIYUKTI_API}job/student/apply/`,
  //         {
  //           job_id: parseInt(job_id),
  //           user_id: localStorage.getItem("user_id"),
  //           round: 1,
  //           status: 1,
  //           feedback: 1,
  //           college_id: 0,
  //           profile_complete_percentage: localStorage.getItem("profile_complete"),
  //         },
  //         {
  //           headers: {
  //             Authorization: "Token " + localStorage.getItem("gw_token"),
  //           },
  //         }
  //       )
  //         .then((res) => {
  //           if (res.data.success) {
  //             router.push(
  //               { query: { job_id: job_id.toString(), applied: true } },
  //               undefined,
  //               { shallow: true }
  //             );
  //             const temp = {
  //               message: res.data.data.message,
  //               company_name: company_name,
  //               job_title: job_title,
  //               job_id: parseInt(job_id),
  //             };

  //             setJobSuccessData(temp);
  //             setSuccess(true);
  //             setApplyButtonLoader(false);
  //             reloadValue.setReloadCard(true);
  //           } else {
  //             alert.error(res.data.error);
  //             setApplyButtonLoader(false);
  //           }
  //         })
  //         .catch((err) => {
  //           alert.error("Some error occurred");
  //           setApplyButtonLoader(false);
  //         });
  //       setJobApply(false);
  //     }
  //   }, [jobApply]);

  const publicText = "show";

  const functionWithSwitch = (parameter) => {
    //console.log("SWIYCH CALLED", parameter);
    switch (parameter) {
      case 0:
        return (
          <LoginPublicJobApply
            setEmail={setEmail}
            email={email}
            setStep={setStep}
            setPhoneNo={setPhoneNo}
            getUser={getUser}
            applyToJob={applyToJob}
            publicText={publicText}
          />
        );
      case 1:
        return (
          <EnterOtpApplyJobs
            setEmail={setEmail}
            email={email}
            setStep={setStep}
            phoneNo={phoneNo}
            getUser={getUser}
            publicText={publicText}
          />
        );
      case 2:
        return (
          <RegisterApplyJob
            setEmail={setEmail}
            email={email}
            setStep={setStep}
            phoneNo={phoneNo}
            getUser={getUser}
            publicText={publicText}
          />
        );
      case 3:
        return (
          <RegisterWorkHistory
            setEmail={setEmail}
            email={email}
            setStep={setStep}
            phoneNo={phoneNo}
            handleClose={handleClose}
            getUser={getUser}
          />
        );

      case 4:
        return (
          <EnterEmailJob
            email={email}
            setEmail={setEmail}
            setStep={setStep}
            setPhoneNo={setPhoneNo}
          />
        );

      case 8:
        return (
          <RecoverEmailPassword
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            from="publicJobInvite"
          />
        );
      case 9:
        return (
          <EnterEmailOtp
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            //checkUser={checkUser}
            // otpEditStep={otpEditStep}
            setOtpForgetPwd={setOtpForgetPwd}
          />
        );
      case 10:
        return (
          <NewPassword
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            otpForgetPwd={otpForgetPwd}
          />
        );
      case 11:
        return <ConfirmPassword setStep={setStep} fromPublicJobApply={true} />;
    }
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [maxWidth, setMaxWidth] = React.useState("sm");

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullScreen}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        className="hide-scrollbar"
        PaperProps={{
          sx: {
            borderRadius: "8px",
            backgroundColor: "#ffff",
            // width: "500px",
            // height: "500px",
            [theme.breakpoints.down("sm")]: {
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "0px",
              borderTopLeftRadius: "14px",
              borderTopRightRadius: "14px",
            },
          },
        }}
      >
        <DialogContent className="hide-scrollbar">
          <Box>
            <Box sx={{ position: "absolute", top: "10px", right: 0 }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Box>
              <Box
                // sx={{ p: 2, pt: 0 }}
                sx={{ p: 0 }}
              >
                {functionWithSwitch(step)}
              </Box>

              {/* <LoginPublicJobApply
                setEmail={setEmail}
                email={email}
                setStep={setStep}
              /> */}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const data = [
  { label: "Software Engineer", year: 1994 },
  { label: "Developer", year: 1972 },
];
