import axios from "axios";
import { checkForToken, eraseCookie, getCookie } from "../Constants/constants";

const fetchApi = async ({
  url,
  method = "get",
  headers = JSON.stringify({
    // headers: { Authorization: `Token ${getCookie("user_token_test")}` },
    withCredentials: true,
  }),
  body = null,
  formData = null,
  alert = null,
  history = null,
  location = null,
  setResponse = () => {},
  setLoading = () => {},
  setError = () => {},
  setPagination = () => {},
  setOneApiCallMade = () => {},
  callBackFn = () => {},
  errorCallback = () => {},
  source = null,
  loginFlow = false,
  checkDomain = false,
  showAlert = false,
}) => {
  setLoading(true);
  await axios[method](
    url,
    formData
      ? formData
      : body
      ? JSON.parse(body)
      : source
      ? { ...JSON.parse(headers), cancelToken: source?.token }
      : JSON.parse(headers),
    formData
      ? source
        ? { ...JSON.parse(headers), cancelToken: source?.token }
        : JSON.parse(headers)
      : body
      ? source
        ? { ...JSON.parse(headers), cancelToken: source?.token }
        : JSON.parse(headers)
      : null
  )
    .then((res) => {
      if (method === "get") {
        if (res?.data?.success) {
          setResponse(res?.data);
          if (Object.keys(res?.data?.data).includes("next")) {
            setPagination({
              end: res?.data?.data?.next ? false : true,
              next: res?.data?.data?.next ? true : false,
              totalDataCount: res?.data?.data?.count,
            });
          }
          setError(null);
          setOneApiCallMade(true);
          if (showAlert) alert.success(res?.data?.data?.message);
          callBackFn(res?.data);
        } else {
          setError(res.data.error);
          if (showAlert) alert.error(res.data.error);
        }
      } else {
        if (res?.data?.success) {
          if (alert) alert.success(res?.data?.data?.message);
          setResponse(res?.data);
          callBackFn(res?.data);
        } else {
          if (!loginFlow && res?.data?.data?.code === 1) {
            errorCallback();
          } else {
            errorCallback(res.data);
            res?.data?.data?.rd_code !== 71 && alert.error(res.data.error);
            setError(res);
          }
        }
      }
      setLoading(false);
    })
    .catch((err) => {
      if (err?.code !== "ERR_CANCELED") setLoading(false);
      // if (
      //   checkDomain &&
      //   err?.response?.status === 401 &&
      //   window.location.pathname !== "/"
      // ) {
      //   alert?.error(err?.response?.data?.error);
      //   window.location = "http://niyojantest.getwork.org:3000/";
      //   return;
      // }
      loginFlow && alert?.error("Something went wrong. Please try again!");

      if (err?.response?.status === 403 && window.location.pathname !== "/") {
        alert?.error(err?.response?.data?.error);
        eraseCookie("user_id");
        eraseCookie("user_token_test");
        eraseCookie("user_token");
        localStorage.removeItem("loggedIn");
        window.location.href = "/";
        return;
      }
      if (err?.code !== "ERR_CANCELED") setError(err);
      if (!loginFlow && method !== "get")
        alert?.error(err?.response?.data?.error);
      throw err;
    });
  // .finally(() => {
  //   setLoading(false);
  // });
};
export default fetchApi;
