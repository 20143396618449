import { Female, Male, Transgender } from "@mui/icons-material";
import { IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useRef, useState, useEffect } from "react";
import { NIYUKTI_API, PRAVESH_API } from "../../../Constants/constants";
import { CustomTextField } from "../../Common/InputText/InputField";
import ConfirmPassword from "../../Section/LoginRightSection/ConfirmPassword";
import EnterEmailJob from "../../Section/LoginRightSection/EnterEmailJob";
import EnterEmailOtp from "../../Section/LoginRightSection/EnterEmailOtp";
import EnterOtpApplyJobs from "../../Section/LoginRightSection/EnterOtpApplyJob";
import LoginPublicJobApply from "../../Section/LoginRightSection/LoginPubicJobApply";
import NewPassword from "../../Section/LoginRightSection/NewPassword";
import RecoverEmailPassword from "../../Section/LoginRightSection/RecoverEmailPassword";
import RegisterApplyJob from "../../Section/LoginRightSection/RegisterApplyJob";
import PublicJobApplyDialog from "./PublicJobApplyDialog";
import RegisterWorkHistory from "../../Section/LoginRightSection/RegisterWorkHistory";
import fetchApi from "../../../Apis/fetchApi";
import { useAlert } from "react-alert";
import AppliedSuccesfullyMobile from "./AppliedSuccesfullyMobile";
import Close from "@mui/icons-material/Close";
export default function RegisterCompany({
  open,
  handleClose,
  handleClickOpen,

  currentStep,
  setEmail,
  email,
  setPhoneNo,
  phoneNo,
  job_id,
  handleClickOpenSuccesfully,
  jobTitle,
  companyName,
}) {
  const theme = useTheme();
  const alert = useAlert();
  // const [fullWidth, setFullWidth] = React.useState("");
  // const [maxWidth, setMaxWidth] = React.useState("xs");
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [step, setStep] = useState(null);

  const [otpForgetPwd, setOtpForgetPwd] = useState("");

  useEffect(() => {
    //console.log("DIalgo", currentStep);
    setStep(currentStep);
  }, [open, currentStep]);

  const publicText = "show";
  const functionWithSwitch = (parameter) => {
    //console.log("SWIYCH CALLED", parameter);
    switch (parameter) {
      case 0:
        return (
          <LoginPublicJobApply
            setEmail={setEmail}
            email={email}
            setStep={setStep}
            setPhoneNo={setPhoneNo}
            getUser={getUser}
            applyToJob={applyToJob}
            publicText={publicText}
          />
        );
      case 1:
        return (
          <EnterOtpApplyJobs
            setEmail={setEmail}
            email={email}
            setStep={setStep}
            phoneNo={phoneNo}
            getUser={getUser}
            publicText={publicText}
          />
        );
      case 2:
        return (
          <RegisterApplyJob
            setEmail={setEmail}
            email={email}
            setStep={setStep}
            phoneNo={phoneNo}
            getUser={getUser}
            publicText={publicText}
          />
        );
      case 3:
        return (
          <RegisterWorkHistory
            setEmail={setEmail}
            email={email}
            setStep={setStep}
            phoneNo={phoneNo}
            handleClose={handleClose}
            getUser={getUser}
          />
        );

      case 4:
        return (
          <EnterEmailJob
            email={email}
            setEmail={setEmail}
            setStep={setStep}
            setPhoneNo={setPhoneNo}
          />
        );

      case 8:
        return (
          <RecoverEmailPassword
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            from="publicJobInvite"
          />
        );
      case 9:
        return (
          <EnterEmailOtp
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            //checkUser={checkUser}
            // otpEditStep={otpEditStep}
            setOtpForgetPwd={setOtpForgetPwd}
          />
        );
      case 10:
        return (
          <NewPassword
            setStep={setStep}
            email={email}
            setEmail={setEmail}
            otpForgetPwd={otpForgetPwd}
          />
        );
      case 11:
        return <ConfirmPassword setStep={setStep} fromPublicJobApply={true} />;
      case 12:
        return (
          <AppliedSuccesfullyMobile
            jobTitle={jobTitle}
            companyName={companyName}
          />
        );
    }
  };

  const getUser = (token) => {
    const userhelper = (data) => {
      sessionStorage.setItem("gw_token", token);
      sessionStorage.setItem("is_pref_filled", data.is_pref_filled);
      sessionStorage.setItem(
        "is_student_resume_uploaded",
        data.is_student_resume_uploaded
      );
      sessionStorage.setItem(
        "profile_complete",
        data.user_type[0].profile_complete
      );
      sessionStorage.setItem(
        "student_profile_check",
        data.student_profile_check ? true : false
      );
      sessionStorage.setItem("user_type", data.user_type[0].main_user);
      sessionStorage.setItem("user_id", data.id);
      sessionStorage.setItem("user_type_id", data.user_type[0].type);
      sessionStorage.setItem("profile_picture", data.profile_picture[0]);
      sessionStorage.setItem("first_name", data.first_name);
      sessionStorage.setItem("last_name", data.last_name);
      sessionStorage.setItem("email", data.email);

      if (
        sessionStorage.getItem("is_student_resume_uploaded") === "true" &&
        job_id !== null
      ) {
        applyToJob(token);
        // setApplyClicked(true);
      } else {
        setStep(3);
      }
    };

    const headers = JSON.stringify({
      // headers: { Authorization: `Token ${token}` },
      withCredentials: true,
    });
    fetchApi({
      url: `${PRAVESH_API}api/profile/details/`,
      headers: headers,
      //alert: alert,
      setResponse: userhelper,
      // callBackFn: helper,
      //loginFlow: true,
      //errorCallback: errorHelper,
    });
  };

  const similarJobsHelper = (data) => {
    //console.log("Similar JObs", data);
    sessionStorage.setItem("similarJobs", JSON.stringify(data?.data?.data));
    setStep(12);
  };

  const getSimilarJobs = (token) => {
    const headers = JSON.stringify({
      //headers: { Authorization: `Token ${token}` },
      withCredentials: true,
    });
    fetchApi({
      url: `${NIYUKTI_API}job/similar_jobs/?count=3`,
      method: "post",
      headers: headers,
      body: JSON.stringify({ job_id: parseInt(job_id) }),
      alert: alert,
      setResponse: similarJobsHelper,
    });
  };

  const applyToJob = (token) => {
    const applyJobHelper = () => {
      console.log("after Aplly");
      getSimilarJobs(token);
      sessionStorage.setItem("jobApplied", "true");
      //handleClose();
    };

    const headers = JSON.stringify({
      //headers: { Authorization: `Token ${token}` },
      withCredentials: true,
    });
    const body = {
      job_id: parseInt(job_id),
      user_id: sessionStorage.getItem("user_id"),
      round: 1,
      status: 1,
      feedback: 1,
      college_id: 0,
      profile_complete_percentage: sessionStorage.getItem("profile_complete"),
    };

    fetchApi({
      url: `${NIYUKTI_API}job/student/apply/`,
      method: "post",
      headers: headers,
      body: JSON.stringify(body),
      alert: alert,
      setResponse: applyJobHelper,
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            [theme.breakpoints.only("xs")]: {
              display: "flex",
              alignItems: "flex-end",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            overflowY: "unset",
            [theme.breakpoints.up("sm")]: {
              borderRadius: "20px",
            },
            [theme.breakpoints.only("xs")]: {
              margin: 0,
              maxWidth: "100%",
              width: "100%",
              borderRadius: "20px 20px 0 0",
              height: "auto" /* height */,
              // overflowY: "scroll",
            },
          },
        }}
        // PaperProps={{
        //   sx: {
        //     borderRadius: "20px",
        //     backgroundColor: "#ffff",
        //     [theme.breakpoints.down("sm")]: {
        //       position: "absolute",
        //       top: "60%",
        //       left: "50%",
        //       transform: "translate(-50%, -50%)",
        //       borderRadius: "0px",
        //       borderTopLeftRadius: "14px",
        //       borderTopRightRadius: "14px",
        //     },
        //   },
        // }}
      >
        <DialogContent className="hide-scrollbar">
          <Box>
            <Box sx={{ position: "absolute", top: "10px", right: 0 }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  [theme.breakpoints.up("sm")]: {
                    display: "none",
                  },
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Box
              // sx={{ p: 2, pt: 0 }}
              sx={{ p: 0, overflowY: "scroll" }}
            >
              {functionWithSwitch(step)}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
