import { Typography, alpha, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/ExpandMoreRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/ExpandLessRounded";
import { IconsCollection } from "../Common/Theme/IconCollection";
import { useTheme } from "@mui/material/styles";
import logo from "../../Assets/Images/png/getwork-logo.png";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NewSideBar = ({ items, key }) => {
  const theme = useTheme();
  const sidebarlink = {
    // width: "220px",
    width: "100%",
    textDecoration: "none",
    position: "relative",
    display: "flex",
    // color: theme.palette.secondary.main,
    color: "#000",
    padding: "8px 0px 8px 16px",
    textDecoration: "none",
    listStyle: "none",
    justifyContent: "space-between",
    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
      textDecoration: "none",
      color: "#fff",
    },
  };

  const blueSideBar = {
    // width: "220px",
    width: "100%",
    textDecoration: "none",
    position: "relative",
    backgroundColor: theme?.palette?.primary?.main,
    color: "#fff ",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 0px 8px 16px",
    listStyle: "none",
    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "4px",
  };
  const subNavSelected = {
    backgroundColor: theme.palette.primary.main,
    textDecoration: "none",
    color: "#fff",
    // width: "220px",
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 0px 8px 16px",
    listStyle: "none",
    height: "50px",
    alignItems: "center",
    padding: "0 16px",
    borderRadius: "4px",
  };
  const dropdownlink = {
    display: "flex",
    // color: theme.palette.secondary.main,
    color: "#000",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    // backgroundcolor: "",
    alignItems: "center",
    padding: "0px 16px 0px 50px",
    justifyContent: "space-between",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: alpha(theme?.palette?.primary?.main, 0.5),
      textDecoration: "none",
      color: "#fff",
    },
  };

  const blueLink = {
    display: "flex",
    textDecoration: "none",
    listStyle: "none",
    height: "30px",
    alignItems: "center",
    padding: "0px 16px 0px 50px",
    justifyContent: "space-between",
    borderRadius: "4px",
    backgroundColor: theme?.palette?.primary?.main,
    textDecoration: "none",
    color: "#fff",
  };

  // const classes = useStyles();
  const [subnav, setSubnav] = useState(false);
  const location = useLocation();
  const [item, setItem] = useState({});
  const history = useHistory();
  const showSubnav = () => {
    setSubnav(!subnav);
  };
  // const dispatch = useDispatch();
  const [navbarClicked, setNavbarClicked] = useState(false);

  // useEffect(() => {
  //   if (
  //     items.subsection &&
  //     items.subsection.length &&
  //     items.subsection.some((i) => i.menu_path === location.pathname)
  //   )
  //     setSubnav(true);
  //   else setSubnav(false);
  // }, [items]);

  useEffect(() => {
    if (location?.pathname === items?.menu_path)
      setItem({
        ...items,
        selected: true,
      });
    else
      setItem({
        ...items,
        selected: false,
      });

    // if (items.subsection && items.subsection.length) {
    //   items.subsection?.forEach((i) => {
    //     if (i.menu_path === location.pathname) i["selected"] = true;
    //     else i["selected"] = false;
    //   });
    // }
  }, [items, location.pathname]);

  const text = {
    fontSize: "14px",
    marginLeft: "16px",
    marginRight: "16px",
    display: "contents",
  };

  return (
    <>
      <div
        onMouseEnter={() => {
          if (item.subsection) showSubnav();
        }}
        onMouseLeave={() => {
          if (item.subsection) showSubnav();
        }}
      >
        {" "}
        <Link
          sx={
            item.selected
              ? blueSideBar
              : item?.subsection &&
                item?.subsection.length &&
                item?.subsection?.some(
                  (i) => i?.menu_path === location?.pathname
                )
              ? subNavSelected
              : sidebarlink
          }
          //style={{ width: "220px", position: "relative" }}
          to={item?.is_sub_menu && item?.is_sidebar ? null : item?.menu_path}
          onClick={() => {
            if (item?.is_sub_menu && item?.is_sidebar) {
              setNavbarClicked(true);
              if (item.subsection) showSubnav();
            } else {
              history.push(item?.menu_path);
            }
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor:'pointer',
            }}
          >
            {/* {IconsCollection[item?.section]} */}
            {IconsCollection[item?.menu_key]}

            <Typography
              variant="body2"
              color="inherit"
              sx={{ fontSize: "14px", marginLeft: "16px", marginRight: "16px" }}
              // className={classes.text}
            >
              {item?.section}
            </Typography>
          </div>
          {item?.is_sub_menu && (
            <div
              style={{
                display: "flex",
                cursor:'pointer',
                // position: "absolute",
                // right: 10,
                // top: 14,
              }}
            >
              {item.subsection && subnav ? (
                <KeyboardArrowUpIcon />
              ) : item.subsection ? (
                <KeyboardArrowDownIcon />
              ) : null}
            </div>
          )}
        </Link>
        {item?.is_sub_menu &&
          subnav &&
          item?.subsection?.map((item, index) => {
            if (item?.is_sidebar) {
              return (
                <Link
                  sx={item.selected ? blueLink : dropdownlink}
                  to={
                    !item?.is_locked
                      ? item?.sub_menu_path
                      : null /* item.sub_menu_path */
                  }
                  onClick={() => {
                    !item?.is_locked && history.push(item?.sub_menu_path);
                  }}
                  key={index}
                >
                  {/* {IconsCollection[item?.sub_menu]} */}
                  <Typography
                    style={{ fontSize: "14px", marginLeft: "5px",cursor:'pointer' }}
                    color="inherit"
                  >
                    {item?.sub_menu}
                  </Typography>

                  {item?.is_locked && IconsCollection["LOCKED"]}
                </Link>
              );
            } else if (item?.is_viewer) {
              return (
                <Link
                  sx={item.selected ? blueLink : dropdownlink}
                  to={item?.sub_menu_path /* item.sub_menu_path */}
                  key={index}
                  onClick={() => {
                    history.push(item?.sub_menu_path);
                  }}
                >
                  {/* {IconsCollection[item?.sub_menu]} */}
                  <Typography
                    style={{ fontSize: "14px", marginLeft: "5px",cursor:'pointer' }}
                    color="inherit"
                  >
                    {item?.sub_menu}
                  </Typography>
                  {item?.is_locked && IconsCollection["LOCKED"]}
                </Link>
              );
            }
          })}
      </div>
    </>
  );
};

export default NewSideBar;
