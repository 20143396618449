import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";
import fetchApi from "../../../Apis/fetchApi";
import {
  emailRegex,
  PRAVESH_API,
  setCookie,
} from "../../../Constants/constants";
import { CustomTextField } from "../../Common/InputText/InputField";

const googleicon = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9995 12.5151C23.9995 11.5083 23.9178 10.7736 23.741 10.0117H12.2441V14.556H18.9925C18.8565 15.6853 18.1218 17.386 16.4891 18.5288L16.4662 18.681L20.1013 21.497L20.3531 21.5221C22.6661 19.386 23.9995 16.2431 23.9995 12.5151Z"
      fill="#4285F4"
    />
    <path
      d="M12.2457 24.4913C15.5518 24.4913 18.3273 23.4028 20.3546 21.5252L16.4906 18.5319C15.4566 19.253 14.0688 19.7564 12.2457 19.7564C9.00752 19.7564 6.25919 17.6204 5.27949 14.668L5.13588 14.6802L1.35607 17.6054L1.30664 17.7428C3.32026 21.7429 7.45641 24.4913 12.2457 24.4913Z"
      fill="#34A853"
    />
    <path
      d="M5.27896 14.6656C5.02046 13.9037 4.87085 13.0873 4.87085 12.2438C4.87085 11.4002 5.02046 10.5839 5.26536 9.82199L5.25851 9.65972L1.43133 6.6875L1.30612 6.74706C0.476205 8.40698 0 10.271 0 12.2438C0 14.2166 0.476205 16.0806 1.30612 17.7405L5.27896 14.6656Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2457 4.73475C14.545 4.73475 16.096 5.72796 16.9804 6.55797L20.4362 3.18373C18.3138 1.21091 15.5518 0 12.2457 0C7.45641 0 3.32026 2.74833 1.30664 6.74838L5.26588 9.82331C6.25919 6.87087 9.00752 4.73475 12.2457 4.73475Z"
      fill="#EB4335"
    />
  </svg>
);

export default function LoginPublicJobApply({
  setStep,
  email,
  setEmail,
  responseGoogle,
  checkUser,
  setOtpEditStep,
  setPrefillData,
  setPrefillCompany,
  getUser,
  applyToJob,
  setTokenStr,
  publicText,
}) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [loadingOtp, setLoginOtp] = useState(false);
  const theme = useTheme();
  const alert = useAlert();

  const initialState = {
    password: "",
    showPassword: false,
    email: email,
  };

  const [values, setValues] = React.useState(initialState);
  const [errorValues, setErrorValues] = React.useState({
    password: "",
    email: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function validate() {
    let emailError = "";
    let passwordError = "";
    let isError = false;
    if (!values?.email || !emailRegex.test(values?.email)) {
      emailError = "Please enter valid email address";
      isError = true;
    }
    if (!values?.password) {
      passwordError = "Please enter valid password";
      isError = true;
    }
    setErrorValues({
      email: emailError,
      password: passwordError,
    });

    return isError ? false : true;
  }

  const helper = (data) => {
    //console.log("After login", data);
    getUser(data?.data?.token);
    // if (data?.data?.code === 1 && data?.data?.rd_code === 30) {
    //   //rd_code === 30 -> company info not available
    //   setStep(5);
    // } else if (data?.data?.code === 1 && data?.data?.rd_code === 31) {
    //   //rd_code === 31 -> incomplete company info available
    //   setPrefillCompany(data?.data?.company_info);
    //   setStep(5);
    // } else if (data?.data?.code === 2 && data?.data?.rd_code === 11) {
    //   //rd_code === 11 -> incomplete user info available
    //   setPrefillData(data?.data?.user_details);
    //   setStep(4);
    // } else if (data?.data?.code === 2) {
    //   setCookie("user_token_test", data?.data?.token, 1);
    //   history.push("/admin/dashboard");
    //   //setStep(12);
    //   //checkUser(data?.data?.token, setLoading);
    // } else if (data?.data?.code === 1 && data?.data?.rd_code === 11) {
    //   setStep(4);
    // } else if (data?.data?.code === 3) {
    //   setStep(6);
    //   setEmail(data?.data?.email);
    // } else if (data?.data?.code === 4 && data?.data?.rd_code === 72) {
    //   setStep(13);
    // } else if (data?.data?.code === 4) {
    //   setStep(7);
    // }
  };
  const loginUser = () => {
    if (validate()) {
      const body = {
        email: values?.email,
        password: values?.password,
        user_type: "Student",
      };
      fetchApi({
        url: `${PRAVESH_API}api/getwork/verify-password/`,
        method: "post",
        alert: alert,
        body: JSON.stringify(body),
        setLoading: setLoading,
        callBackFn: helper,
        //loginFlow: true,
        alert: alert,
      });
    }
  };
  const helper2 = (data) => {
    setStep(1);
    //setOtpEditStep(2);
  };
  const loginOtp = () => {
    if (!values?.email || !emailRegex.test(values?.email)) {
      alert.error("Please enter valid email address!");
      return;
    }
    const body = {
      email: values?.email,
      user_type: "student",
    };
    fetchApi({
      url: `${PRAVESH_API}api/getwork/generate-otp/`,
      method: "post",
      alert: alert,
      body: JSON.stringify(body),
      setLoading: setLoginOtp,
      callBackFn: helper2,
      loginFlow: true,
      alert: alert,
    });
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        {publicText ? (
          <Typography
            variant="h4"
            style={{ fontWeight: "600", textAlign: "left" }}
          >
            Login
          </Typography>
        ) : (
          <Typography variant="h4" style={{ fontWeight: "500" }}>
            Login
          </Typography>
        )}
      </div>
      <Grid container spacing={3}>
        <Grid xs={12} md={12} style={{ display: "grid" }}>
          <CustomTextField
            size="small"
            label="Enter Your Official Email ID "
            variant="outlined"
            disabled
            value={values?.email}
            onChange={(event) => {
              setEmail(event.target.value);
              setValues({ ...values, email: event.target.value });
              setErrorValues({ ...errorValues, email: "" });
            }}
            error={errorValues?.email ? true : false}
            helperText={errorValues?.email ? errorValues?.email : ""}
          />
        </Grid>
        <Grid xs={12} md={12} style={{ display: "grid" }}>
          <FormControl variant="outlined" size="small">
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                fontSize: "14px",
                color: "#7e7e7e",
                paddingTop: "1.2px",
              }}
            >
              Password
            </InputLabel>
            <OutlinedInput
              style={{
                borderRadius: "8px",
                height: "40px",
              }}
              // inputProps={{ style: { fontSize: 14 } }}
              // InputLabelProps={{ style: { fontSize: 14 } }}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values?.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText
              style={{
                fontSize: "12px",
              }}
              id="standard-weight-helper-text"
              // style={{errorValues?password && errorPassword}}
            >
              {errorValues?.password ? errorValues?.password : ""}
            </FormHelperText>
          </FormControl>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox size="small" name="checkedB" color="primary" />
              }
              label={<Typography variant="body2">Keep me signed in</Typography>}
            />
            <Button
              variant="text"
              color="primary"
              style={{ fontWeight: "400", padding: "0px", fontSize: "12px" }}
              onClick={() => {
                setStep(8);
              }}
            >
              Forgot Password?
            </Button>
          </div>
        </Grid>
        <Grid xs={12} md={12} style={{ display: "grid" }}>
          <Button
            color="primary"
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "8px",
              display: "flex",
              fontWeight: "400",
              fontSize: "15px",
            }}
            variant="contained"
            onClick={() => {
              loginUser();
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Continue"
            )}
          </Button>
        </Grid>
        <Grid xs={12} md={12} style={{ display: "grid" }}>
          <Button
            color="primary"
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "8px",
              display: "flex",
              fontWeight: "400",
              fontSize: "15px",
            }}
            variant="outlined"
            onClick={() => {
              loginOtp();
            }}
          >
            {loadingOtp ? (
              <CircularProgress style={{ color: "#007bff" }} size={18} />
            ) : (
              "Login via OTP"
            )}
          </Button>
        </Grid>
        <Grid xs={12} md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Divider style={{ width: "45%" }} />
            <Typography
              variant="body2"
              color="initial"
              style={{ color: "#B0B6BA" }}
            >
              OR
            </Typography>
            <Divider style={{ width: "45%" }} />
          </div>
        </Grid>
        <Grid xs={12} md={12} styl={{ display: "grid" }}>
          <GoogleLogin
            clientId="468384875560-0abm3b0a8mniumht5t5fv1br7flqkbg7.apps.googleusercontent.com"
            isSignedIn={false}
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                sx={{
                  height: "40px",
                  boxShadow: "none",
                  borderRadius: "8px",
                  display: "flex",
                  fontWeight: "400",
                  fontSize: "15px",
                  width: "100%",
                }}
                variant="outlined"
                color="primary"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {socialLoading ? (
                    <CircularProgress style={{ color: "#007BFF" }} size={18} />
                  ) : (
                    <>
                      {googleicon}
                      <Typography
                        variant="body1"
                        color="primary"
                        style={{ marginLeft: "10px", fontSize: "15px" }}
                      >
                        {" "}
                        Continue with Google
                      </Typography>
                    </>
                  )}
                </div>
              </Button>
            )}
            onSuccess={(response) => {
              responseGoogle(response, setSocialLoading);
            }}
            onFailure={(response) => {
              responseGoogle(response, setSocialLoading);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              /* justifyContent: "space-between", */
            }}
            // className={classes.create}
          >
            <Typography variant="body2" style={{ fontFamily: "gilRoy" }}>
              Wrong Email?
            </Typography>

            <Button
              size="small"
              style={{ color: "#007bff" }}
              onClick={() => {
                setStep(1);
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: theme.palette.primary.main, fontWeight: 900 }}
              >
                Try with different email.
              </Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
