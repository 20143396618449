import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import fetchApi from "../../../Apis/fetchApi";
import { PRAVESH_API } from "../../../Constants/constants";

export default function EnterOtpApplyJobs({
  setStep,
  email,
  setEmail,
  checkUser,
  otpEditStep,
  setPrefillData,
  setPrefillCompany,
  phoneNo,
  getUser,
  publicText,
}) {
  const alert = useAlert();

  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [timerComplete, setTimerComplete] = React.useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  const handleChange = (code) => {
    setCode(code);
    setCodeError(null);
  };

  const helper2 = (data) => {
    if (data.success) {
      //setTimerComplete(false);
      setSeconds(30);
    } else {
      if (data?.data?.code === 2) {
        alert.error(data?.data?.code_text);
      }
    }
  };
  const resendOtp = () => {
    fetchApi({
      url: `${PRAVESH_API}api/getwork/resend-otp/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        email: email,
        user_type: "student",
        mobile: phoneNo,
      }),
      //setLoading: setLoadingSubmit,
      callBackFn: helper2,
      loginFlow: true,
    });
    // axios
    //   .post(`${PRAVESH_API}api/resend_otp/`, {
    //     email: email,
    //     user_type: "company",
    //   })
    //   .then((res) => {
    //     if (res?.data?.success) {
    //       alert.success(res?.data?.data?.message);
    //       setTimerComplete(false);
    //     } else {
    //       if (res?.data?.data?.code === 2) {
    //         alert.error(res?.data?.data?.code_text);
    //       } else alert.error(res?.data?.error);
    //     }
    //   })
    //   .catch((err) => {
    //     throw err;
    //   });
  };
  const helper = (data) => {
    // console.log("VALIDATE Otp", data);
    // console.log(data?.data?.token);

    localStorage.setItem("token", data?.data?.token);
    getUser(data?.data?.token);
    // if (data?.data?.code === 1 && data?.data?.rd_code === 30) {
    //   setStep(5);
    // } else if (data?.data?.code === 3) {
    //   setStep(6);
    //   setEmail(data?.data?.email);
    // } else if (data?.data?.code === 2 && data?.data?.rd_code === 11) {
    //   setPrefillData(data?.data?.user_details);
    //   setStep(4);
    // } else if (data?.data?.rd_code === 60) {
    //   //checkUser(data?.data?.token, setLoadingSubmit);
    //   //("user_token", data?.data?.token, 1);
    //   history.push("/admin/dashboard");
    //   //setStep(12);
    // } else if (data?.data?.code === 4) {
    //   setStep(7);
    // }
  };
  const errorHelper = () => {
    setCode("");
  };

  const validateDataOtp = () => {
    if (!code) {
      setCodeError("Please Enter Your Verification Code");
      return;
    }
    if (code && code?.length < 4) {
      setCodeError("Please Enter Your Verification Code Correctly");
      return;
    }
    fetchApi({
      url: `${PRAVESH_API}api/getwork/validate-otp/
      `,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        email: email,
        user_type: "student",
        otp: code,
        mobile: phoneNo,
      }),
      setLoading: setLoadingSubmit,
      callBackFn: helper,
      loginFlow: true,
      alert: alert,
      errorCallback: errorHelper,
    });
  };

  const [seconds, setSeconds] = React.useState(30);

  React.useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          fontWeight: "400",
          marginBottom: "20px",
        }}
      >
        {otpEditStep === 4 ? "Verify with OTP" : "Login with OTP"}
      </Typography>

      {publicText ? (
        <Typography
          variant="h4"
          style={{
            fontWeight: "600",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          {otpEditStep === 4 ? "Verify with OTP" : "Login with OTP"}
        </Typography>
      ) : (
        <Typography
          variant="h4"
          style={{
            textAlign: "center",
            fontWeight: "400",
            marginBottom: "20px",
          }}
        >
          {otpEditStep === 4 ? "Verify with OTP" : "Login with OTP"}
        </Typography>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            fontWeight: "500",
            padding: 12,
          }}
        >
          <span style={{ color: "#707070" }}>Sent to</span>{" "}
          <span style={{ color: theme.palette.primary.main }}> {email}</span>
        </Typography>
        <EditIcon
          style={{
            color: theme.palette.primary.main,
            fontSize: "18px",
            cursor: "pointer",
            marginLeft: "-7px",
          }}
          onClick={() => {
            setStep(otpEditStep);
          }}
        />
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "400",
                  padding: 6,
                }}
              >
                Enter OTP
              </Typography>
              <OtpInput
                value={code}
                onChange={(e) => handleChange(e)}
                numInputs={4}
                separator={<span style={{ width: "20px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: ".5px solid #a5a5a5",
                  borderRadius: "8px",
                  width: "54px",
                  height: "54px",
                  fontSize: "20px",
                  fontFamily: "gilRoy",
                  // color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: 12,
                }}
              >
                <span style={{ color: "#ed1111" }}> </span>{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  padding: 12,
                }}
              >
                <span
                  style={
                    /* timerComplete */ !seconds
                      ? { color: theme.palette.primary.main, cursor: "pointer" }
                      : { color: "#707070" }
                  }
                  onClick={() => {
                    if (/* timerComplete */ !seconds) resendOtp();
                  }}
                >
                  Resend OTP
                </span>
                {
                  /* !timerComplete */ seconds ? (
                    <span style={{ color: "#707070" }}>{""}&nbsp; in </span>
                  ) : (
                    <></>
                  )
                }
                <span style={{ color: theme.palette.primary.main }}>
                  {
                    /* !timerComplete */ seconds ? (
                      // <Countdown
                      //   date={Date.now() + 30000}
                      //   onComplete={() => setTimerComplete(true)}
                      //   renderer={(props) => (
                      //     <div>
                      //       <b>{props.seconds + " sec"}</b>
                      //     </div>
                      //   )}
                      // />
                      <div>{seconds + " sec"}</div>
                    ) : (
                      <></>
                    )
                  }
                </span>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} style={{ display: "grid" }}>
            {" "}
            <Button
              variant="contained"
              sx={{
                display: "flex",
                borderRadius: "8px",
                boxShadow: "none",
                height: "40px",
                fontWeight: "400",
                fontSize: "15px",
              }}
              onClick={() => {
                validateDataOtp();
              }}
              color="primary"
            >
              {loadingSubmit ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                " Verify"
              )}
            </Button>
            <Typography
              variant="body2"
              style={{
                color: "#A3A3A3",
                textAlign: "center",
                marginTop: "20px",
                // marginBottom: "20px",
                fontWeight: "600",
                padding: 12,
              }}
            >
              Check spam folders if email is not in the inbox.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
