import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  eraseCookie,
  getCookie,
  NIYUKTI_API,
  PRAVESH_API,
} from "../Constants/constants";
export const getAvailableUnlocks = createAsyncThunk(
  "availableUnlocks/getAvailableUnlocks",
  async ({ typeId, userId }) => {
    const url = `${PRAVESH_API}company_saas/profile_unlock_info/?user_type_id=${typeId}&user_id=${userId}`;
    const res = await axios.get(url, {
      // headers: { Authorization: `Token ${getCookie("user_token_test")}` },
      withCredentials: true,
    });
    return res.data.data;
  }
);

const availableUnlockSlice = createSlice({
  name: "availableUnlocks",
  initialState: {
    availableUnlocks: {},
    loading: false,
  },
  reducers: {
    logoutAvailableUnlocks(state, action) {
      state.availableUnlocks = {};
      state.loading = false;
    },
  },
  extraReducers: {
    [getAvailableUnlocks.pending]: (state, action) => {
      state.loading = true;
    },
    [getAvailableUnlocks.fulfilled]: (state, action) => {
      state.loading = false;
      state.availableUnlocks = action?.payload;
    },
    [getAvailableUnlocks.rejected]: (state, action) => {
      state.loading = false;
      eraseCookie("user_id");
      eraseCookie("user_token_test");
      eraseCookie("user_token");
      localStorage.removeItem("loggedIn");
      //   window.location.href = "/";
    },
  },
});

export const { logoutAvailableUnlocks } = availableUnlockSlice.actions;
export default availableUnlockSlice.reducer;
