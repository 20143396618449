import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Typography,
  Autocomplete,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Close from "@mui/icons-material/Close";
import vactor from "../../../Assets/Images/svg/Done.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import JobCard from "../../Cards/Profile/JobCard";
import SimilarJobCard from "../../Cards/Profile/SimilarJobCard";
import useCheckMobileScreen from "../../../Hooks/useCheckMobileScreen";

export default function AppliedSuccesfullyMobile({ jobTitle, companyName }) {
  const theme = useTheme();
  // const [fullWidth, setFullWidth] = React.useState("");
  // const [maxWidth, setMaxWidth] = React.useState("xs");
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const similarJobs = JSON.parse(sessionStorage?.getItem("similarJobs")); // sessionStorage?.getItem("similarJobs")
  
  const isMobileScreen = useCheckMobileScreen();
  return (
    <React.Fragment>
      <Grid
        container
        sx={isMobileScreen ? { height: "80vh", overflowY: "scroll" } : {}}
      >
        <Grid
          xs={12}
          sm={6}
          md={6}
          sx={{
            [theme.breakpoints.up("sm")]: {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            <CheckCircleIcon sx={{ color: "#12DC00" }} />
            <Typography
              variant="body1"
              sx={{ color: "#12DC00", fontWeight: "600", mb: 2, mt: 2 }}
            >
              Applied Succesfully
            </Typography>
            <Typography variant="body2" sx={{ color: "#6C757D" }}>
              You have successfully applied
            </Typography>
            <Typography variant="body2" sx={{ color: "#6C757D", width: 320 }}>
              {`${jobTitle} job at ${companyName}`}
            </Typography>
          </Box>
        </Grid>

        <Box sx={{ overflowX: "scroll", display: "flex" }}>
          {similarJobs &&
            similarJobs?.map((job) => (
              <Box sx={{ ml: 2, mb: 2 }}>
                <SimilarJobCard
                  data={{
                    ...job,
                    job_title: job?.job_title,
                    start_date: job?.job_duration_start,
                    ctc_min: job?.ctc_min,
                    apply_by: job?.apply_end_date,
                    skill: job?.eligibility_criteria?.skills,
                    location: job?.job_location,
                  }}
                />
              </Box>
            ))}
        </Box>
        <Grid xs={12} sx={{ display: "grid", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ boxShadow: "none" }}
            onClick={() => {
              window.open("https://getwork.org/public/jobs", "_blank");
            }}
          >
            View More Jobs
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const data = [
  { label: "Software Engineer", year: 1994 },
  { label: "Developer", year: 1972 },
];
