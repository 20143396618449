import PostAddIcon from "@mui/icons-material/PostAddRounded";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Button,
  /*   Link, */
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../Assets/Images/png/getwork-logo.png";
import secondlogo from "../../Assets/Images/png/logo.png";
import AvatarMenu from "../../Components/Common/InputText/AvatarMenu";
import Search from "../../Components/Common/InputText/Search";
import NewSideBar from "../../Components/Navbar/NewSideBar";
import NotificationBell from "../../Components/Section/Notification/NotificationBell";
import useCheckMobileScreen from "../../Hooks/useCheckMobileScreen";
import { getAvailableUnlocks } from "../../Redux/availableUnlockSlice";
import { getCreditDetails } from "../../Redux/CreditDetailSlice";
import { getNotifications } from "../../Redux/notifcationSlice";
import { getUserDetails } from "../../Redux/userDetailSlice";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const postJob = {
  padding: "10px",
  display: "flex",
  alignItems: "center",
  padding: "8px",
  marginBottom: "18px",
  height: "45px",
  marginLeft: "6px",
  cursor: "pointer",
  backgroundColor: "#000",
  borderRadius: "8px",
  width: "80%",
  display: "flex",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#000",
  },
};

export default function Layout({ children }) {
  const [sideBarData, setSideBarData] = useState();
  const [modifiedSideBarData, setmodifiedSideBarData] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [oneApiCallMade, setOneApiCallMade] = useState(false);
  const state = useSelector(
    (state) => state?.userDetails?.userDetails?.access_control
  );
  const userDetails = useSelector(
    (state) => state?.userDetails?.userDetails?.user_details
  );
  const availableUnlocksState = useSelector(
    (state) => state?.availableUnlocks?.availableUnlocks
  );
  const creditDetailState = useSelector(
    (state) => state?.creditDetails?.creditDetails
  );
  const notificationDetails = useSelector(
    (state) => state?.notification?.notificationDetails
  );
  const domainDetail = useSelector((state) => state?.domainDetail?.data);

  useEffect(() => {
    if (userDetails?.type_id && userDetails?.id) {
      Object.keys(availableUnlocksState).length === 0 &&
        dispatch(
          getAvailableUnlocks({
            typeId: userDetails?.type_id,
            userId: userDetails?.id,
          })
        );
      Object.keys(creditDetailState).length === 0 &&
        dispatch(
          getCreditDetails({
            typeId: userDetails?.type_id,
            userId: userDetails?.id,
            companyId: userDetails?.company,
          })
        );
      Object.keys(notificationDetails).length === 0 &&
        dispatch(getNotifications());
    }
  }, [userDetails]);

  useEffect(() => {
    setSideBarData(state);
  }, [state]);

  useEffect(() => {
    if (/* localStorage.getItem("isLoggedin") === "true" && */ !state) {
      dispatch(getUserDetails());
    }
  }, []);

  const checkUrlAccessValidity = (path) => {
    let flag = false;
    const check = sideBarData?.map((item) => {
      const check2 = item?.subsection?.map((item2) => {
        if (
          path.includes(item2?.menu_path) /* || path === "/admin/dashboard" */
        ) {
          flag = true;
        } else {
        }
      });
    });
    return flag;
  };
  // useEffect(() => {
  //   if (sideBarData?.length > 0) {
  //     const matched = checkUrlAccessValidity(location?.pathname);
  //     if (!matched) {
  //       history.push("/admin/dashboard");
  //       window.location.reload();
  //     }
  //   }
  // }, [sideBarData, location?.pathname]);

  useEffect(() => {
    if (location?.pathname === "/admin/dashboard" && modifiedSideBarData) {
      const matched = checkUrlAccessValidity(location?.pathname);
      if (!matched) {
        const redirectPath = modifiedSideBarData[0]?.menu_path;
        history.push(redirectPath);
        window.location.reload();
      }
    }
  }, [modifiedSideBarData, location?.pathname]);

  useEffect(() => {
    if (sideBarData?.length > 0) {
      // for removing these subsections from the sidebar option

      const temp = sideBarData?.map((item) => {
        if (item?.is_sub_menu) {
          const subsection = item?.subsection?.filter((item2) => {
            if (!item2?.is_sidebar) {
              return false;
            } else {
              return true;
            }
          });
          return { ...item, subsection: subsection };
        } else if (item?.is_sidebar) return item;
      });
      setmodifiedSideBarData(temp);
    }
  }, [sideBarData]);

  // useEffect(() => {
  //   if (sideBarData?.length > 0) {
  //     // for removing these subsections from the sidebar option

  //     const tempSections = sideBarData?.filter((item) => {
  //       if (
  //         item?.section !== "Post Job" &&
  //         item?.section !== "Reports"
  //       ) {
  //         //item?.section !== "Reports"
  //         return item;
  //       }
  //     });
  //     const subsectionsToRemove = ["WELCM", "TRACK", "JOBD", "PUBJOB"];

  //     const temp = tempSections?.map((item) => {
  //       const subsection = item?.subsection?.filter((item2) => {
  //         if (
  //           subsectionsToRemove.includes(item2?.menu_key) ||
  //           item2?.sub_menu_path === "/hiring-rules"
  //         ) {
  //           // return ({ ...item2, selected: !item?.selected })
  //           return false;
  //         } else {
  //           return true;
  //         }
  //       });
  //       return { ...item, subsection: subsection };
  //     });

  //     // welcome section is being removed here
  //     const temp2 = temp?.filter((item) => {
  //       if (item?.section === "Welcome Page") {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     });

  //     setmodifiedSideBarData(temp2);
  //     // setSideBarData(temp)
  //   }
  // }, [sideBarData]);

  //   const handleChange = (panel) => (event, newExpanded) => {
  //     localStorage.setItem("panel", panel);
  //     setExpanded(newExpanded ? panel : false);
  //   };
  const isMobileScreen = useCheckMobileScreen();
  const [showNavbar, setShowNavbar] = useState(
    window.location.pathname === "/track-job/" ? true : false
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="absolute"
          sx={{
            borderBottom: "1px solid #E9E9E9",
            backgroundColor: "#fff",
            boxShadow: "none",
            position: "absolute",
            top: showNavbar === true ? "-62px" : "unset",
          }}
        >
          <Toolbar
            sx={{
              pr: "15px !important",
              pl: "15px !important",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "left",
                // paddingLeft: "40px",
                width: 100,
                backgroundColor: "#fff",
                height: 50,
              }}
            >
              <img
                src={
                  userDetails?.company_details?.logo
                    ? userDetails?.company_details?.logo
                    : logo
                }
                alt="logo"
                style={{
                  // margin: "auto",
                  objectFit: "contain",
                  maxWidth: "100%",
                  // width: "auto",
                  maxHeight: "100%",
                }}
              />
            </Box>

            <Grid container spacing={2} sx={{ width: "100%" }}>
              <Grid xs={2} sm={2} md={2} /* md={3} */></Grid>

              <Grid xs={5} sm={5} md={5} /* md={4} */>
                {isMobileScreen ? (
                  <Box sx={{ width: "150px" }}></Box>
                ) : (
                  <Search />
                )}
              </Grid>
              {/* <Grid xs={1} sm={1} md={1}>
                <Button
                  style={{
                    //borderRadius: "30px",
                    borderRadius: "8px",
                    boxShadow: "none",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    width: "8rem",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push("/post-job");
                  }}
                >
                  Post Job
                </Button>
              </Grid> */}

              <Grid xs={5} sm={5} md={5}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{
                      //borderRadius: "30px",
                      borderRadius: "8px",
                      boxShadow: "none",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      width: "8rem",
                      marginRight: 10,
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      history.push("/post-job");
                    }}
                  >
                    Post Job
                  </Button>
                  {!isMobileScreen && (
                    <div>
                      <NotificationBell />
                    </div>
                  )}
                  {/* <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                    <Tooltip title={"Help & Support"} placement="bottom">
                      <IconButton
                        onClick={() => {
                          history.push("/help");
                        }}
                      >
                        <HelpOutlineOutlinedIcon sx={{ color: "#000" }} />
                      </IconButton>
                    </Tooltip>
                  </div> */}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      ml: 2,
                    }}
                  >
                    <AvatarMenu userDetails={userDetails} />
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                right: "50%",
                bottom: "-10px",
              }}
            >
              <IconButton
                color="primary"
                size="small"
                sx={{
                  backgroundColor: "#00203F",
                  p: 0.2,
                  "&:hover": {
                    backgroundColor: "#00203F",
                  },
                }}
                onClick={() => setShowNavbar(!showNavbar)}
              >
                <KeyboardDoubleArrowDownIcon
                  sx={{
                    color: "#fff",
                    fontSize: "22px",
                    transform: showNavbar === true ? "unset" : "rotate(180deg)",
                  }}
                />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        {!isMobileScreen && (
          <div className="nav" id="navbar">
            <nav className="nav__container">
              {state?.some((item) => item?.section === "Post Job") ? (
                <Box
                  style={{ marginTop: "75px" }}
                  // onMouseOver={handleDrawerOpen}
                >
                  <Link
                    to={`/post-job`}
                    style={{ textDecoration: "none", display: "contents" }}
                  >
                    <div style={postJob} variant="contained" color="primary">
                      <PostAddIcon
                        style={{
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "3px",
                        }}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          margin: "auto",
                          marginLeft: "25px",
                          color: "white",
                        }}
                      >
                        Post Job{" "}
                      </Typography>
                    </div>
                  </Link>
                </Box>
              ) : (
                <>{!showNavbar && <Box sx={{ mt: "86px" }}></Box>}</>
              )}
              <div>
                <div
                  style={
                    {
                      // height: "70vh",
                    }
                  }
                >
                  {modifiedSideBarData?.length > 0 &&
                    modifiedSideBarData?.map((item, index) => {
                      return <NewSideBar items={item} key={index} />;
                    })}
                  <div className="img-top">
                    <img src={logo} className="open-img-sidebar" />
                  </div>
                  <div className="img-top-second">
                    <img src={secondlogo} className="close-img-sidebar" />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        )}
        <Box
          component="main"
          sx={{
            // backgroundColor: "#fff",
            background:
              window.location.pathname === "/ai-job-post"
                ? "radial-gradient(139.81% 139.81% at 49.28% -1.56%, #ECF6FF 4.32%, #FAFAFA 71.73%)"
                : "#fff",
            // backgroundColor: (theme) =>
            //   theme.palette.mode === "light"
            //     ? theme.palette.grey[100]
            //     : theme.palette.grey[900],
            flexGrow: 1,
            height: "100dvh",
            overflow: "auto",
          }}
        >
          {!showNavbar && <Toolbar />}
          <Container
            maxWidth="xl"
            sx={{
              position: "relative",
              mt:
                window.location.pathname === "/open-jobs"
                  ? 0
                  : window.location.pathname === "/track-job/"
                  ? 0
                  : 2.5,
              mb:
                window.location.pathname === "/open-jobs"
                  ? 0
                  : window.location.pathname === "/track-job/"
                  ? 0
                  : 2.5,
              paddingLeft:
                window.location.pathname === "/getwork-plan-pricing" ||
                window.location.pathname === "/open-jobs"
                  ? "0px!important"
                  : "",
              paddingRight:
                window.location.pathname === "/getwork-plan-pricing" ||
                window.location.pathname === "/open-jobs"
                  ? "0px!important"
                  : "",
              height:
                window.location.pathname === "/track-job/" ? "100dvh" : "unset",
            }}
          >
            {children}
          </Container>
        </Box>
      </Box>
    </>
  );
}
