import CheckIcon from "@mui/icons-material/Check";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import fetchApi from "../../../Apis/fetchApi";
import { PRAVESH_API, setCookie } from "../../../Constants/constants";
import { CustomTextField } from "../../Common/InputText/InputField";
import { Male, Female, Transgender } from "@mui/icons-material";
import useCheckMobileScreen from "../../../Hooks/useCheckMobileScreen";

export default function RegisterApplyJob({
  setStep,
  email,
  setOtpEditStep,
  setEmail,
  data,
  prefillData,
  publicText,
}) {
  // const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useCheckMobileScreen();
  const selectgender = {
    borderColor: theme.palette.primary.main,
    backgroundColor: "#e8f3ff",
    color: theme.palette.primary.main,
    borderRadius: "8px",
    textTransform: "capitalize",
    marginRight: "20px",
  };
  const disablegender = {
    borderColor: theme.palette.grey[500],
    backgroundColor: "#fff",
    color: theme.palette.grey[500],
    borderRadius: "8px",
    textTransform: "capitalize",
    marginRight: "20px",
  };
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [appliedReferral, setAppliedReferral] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = React.useState({
    name: prefillData?.full_name ? prefillData?.full_name : "",
    email: email,
    phone: prefillData?.phone ? prefillData?.phone : "",
    password: "",
    showPassword: false,
    referralCode: "",
  });

  const [errorValues, setErrorValues] = React.useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const [isApplied, setIsApplied] = React.useState(false);
  const [showPassErr, setShowPassErr] = useState(false);

  const [checkPwdLength, setCheckPwdLength] = useState(false);
  const [checkCapital, setCheckCapital] = useState(false);
  const [checkDigit, setCheckDigit] = useState(false);
  const [checkNoSpace, setCheckNoSpace] = useState(false);
  const [gender, setGender] = React.useState(null);
  const [cityStateList, setCityStateList] = useState();
  const [cityState, setCityState] = useState(null);
  const [selectedGender, setSelectedGender] = React.useState({
    male: false,
    female: false,
    others: false,
  });

  const onPassWordChange = () => {
    //setShowPassErr(false);
    let isError = false;

    let passwordError = "";
    if (!values?.password) {
      //setShowPassErr(true);
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckPwdLength(true);
      setCheckDigit(true);
      setCheckNoSpace(true);
      setCheckCapital(true);
      isError = true;
      setShowPassErr(true);
    }
    if (
      values?.password &&
      !(values?.password.length > 7 && values?.password.length < 21)
    ) {
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckPwdLength(true);
      isError = true;
      setShowPassErr(true);
    }
    const str = /(?=.*?[A-Z]).*/;
    if (values?.password && !str.test(values?.password)) {
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckCapital(true);
      isError = true;
      setShowPassErr(true);
    }
    const digitRegex = /^(?=.*\d).+$/;

    if (values?.password && !digitRegex.test(values?.password)) {
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckDigit(true);
      isError = true;
      setShowPassErr(true);
    }
    if (
      values?.password &&
      values?.password.length > 1 &&
      values?.password.split(" ").length > 1
    ) {
      setCheckNoSpace(true);
      isError = true;
      setShowPassErr(true);
    }
    setErrorValues({
      ...errorValues,
      password: passwordError,
    });

    return isError ? false : true;
  };

  const validateForm = () => {
    let isError = false;
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let passwordError = "";
    let genderError = "";
    let cityStateError = "";

    if (!values?.name) {
      nameError = "Please enter full name";
      isError = true;
    }
    if (!values?.email) {
      emailError = "Please enter valid email address";
      isError = true;
    }
    if (!values?.phone || values?.phone.length !== 10) {
      phoneError = "Please enter valid phone number";
      isError = true;
    }
    if (!gender) {
      genderError = "Please select gender";
      isError = true;
    }
    if (!cityState) {
      cityStateError = "Please select City State";
      isError = true;
    }
    if (!prefillData && !values?.password) {
      //setShowPassErr(true);
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckPwdLength(true);
      setCheckDigit(true);
      setCheckNoSpace(true);
      setCheckCapital(true);
      /*   setPwdErrors({
        ...pwdErrors,
        checkPwdLength: true,
        checkCapital: true,
        checkDigit: true,
        checkNoSpace: true,
      }); */
      isError = true;
      setShowPassErr(true);
    }
    if (
      !prefillData &&
      values?.password &&
      !(values?.password.length > 7 && values?.password.length < 21)
    ) {
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }
      setCheckPwdLength(true);

      /* setPwdErrors({
        ...pwdErrors,
        checkPwdLength: true,
      }); */
      isError = true;
      setShowPassErr(true);
    }
    const str = /(?=.*?[A-Z]).*/;
    if (!prefillData && values?.password && !str.test(values?.password)) {
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }

      setCheckCapital(true);
      /*  setPwdErrors({
        ...pwdErrors,
        checkCapital: true,
      }); */
      isError = true;
      setShowPassErr(true);
    }
    const digitRegex = /^(?=.*\d).+$/;

    if (
      !prefillData &&
      values?.password &&
      !digitRegex.test(values?.password)
    ) {
      if (!passwordError) {
        passwordError = "Password length should be atleast 6 character";
      }

      setCheckDigit(true);

      /*  setPwdErrors({
        ...pwdErrors,
        checkDigit: true,
      }); */
      isError = true;
      setShowPassErr(true);
    }
    if (
      !prefillData &&
      values?.password &&
      values?.password.split(" ").length > 1
    ) {
      setCheckNoSpace(true);

      /* setPwdErrors({
        ...pwdErrors,
        checkNoSpace: true,
      }); */
      isError = true;
      setShowPassErr(true);
    }
    setErrorValues({
      name: nameError,
      email: emailError,
      phone: phoneError,
      password: passwordError,
      gender: genderError,
      cityState: cityStateError,
    });

    return isError ? false : true;
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setValues({
        ...values,
        name: `${data?.first_name} ${data.last_name ? data.last_name : ""}`,
        phone: data?.phone,
        email: data?.email,
      });
      setEmail(data?.email);
    }
  }, [data]);
  const [changed, setChanged] = useState(0);
  useEffect(() => {
    if (changed > 0) onPassWordChange();
  }, [changed]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrorValues({ ...errorValues, [prop]: "" });
    if (prop === "password") {
      setCheckPwdLength(false);
      setCheckDigit(false);
      setCheckNoSpace(false);
      setCheckCapital(false);
      //setShowPassErr(false);
      setChanged((prev) => prev + 1);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const helper = (data) => {
    // console.log(data);
    localStorage.setItem("token", data?.data?.token);
    localStorage.setItem("new_user_id", data?.data?.id);
    setStep(3);
    // if (data.data.rd_code === 20) {
    //   setStep(3);
    //   setOtpEditStep(1);
    // } else {
    //   //setCookie("user_token", data?.data?.token, 1);
    //   history.push("/admin/dashboard");
    //   //user updated
    //   //setStep(12);
    // }
  };
  const signup = () => {
    if (validateForm()) {
      if (!continueCheck) {
        alert.error("Please check GetWork's T&C!");
        return;
      }
      const [first, ...last] = values?.name?.split(" ");
      let body = {
        first_name: first,
        last_name: last.join(" "),
        email: values?.email,
        phone: values?.phone,
        gender: gender,
        curr_city_id: cityState?.city_id,
        password: values?.password ? values?.password : null,
        user_type: "Student",
      };

      if (!prefillData && appliedReferral) {
        body["referral_code"] = appliedReferral;
      }
      fetchApi({
        url: `${PRAVESH_API}api/getwork/create/`,
        method: "post",
        alert: alert,
        body: JSON.stringify(body),
        setLoading: setIsLoading,
        callBackFn: helper,
        loginFlow: true,
        alert: alert,
      });
    }
  };

  const cityStateHelper = (data) => {
    //console.log("DDTDTATTDTATD", data);
    setCityStateList(data?.data);
  };
  const getCityState = () => {
    fetchApi({
      url: `${PRAVESH_API}api/location/city_state`,
      alert: alert,
      setResponse: cityStateHelper,
    });
  };
  useEffect(() => {
    getCityState();
  }, []);

  const [referralMsg, setReferralMsg] = useState("");
  const [referralSuccess, setReferralSuccess] = useState(false);
  const [referralLoading, setReferralLoading] = useState(false);

  const [continueCheck, setContinue] = useState(false);

  //   const applyReferral = () => {
  //     const body = {
  //       referral_code: values?.referralCode,
  //     };
  //     setReferralLoading(true);
  //     axios
  //       .post(`${PRAVESH_API}company_saas/login/apply_referral/`, body)
  //       .then((res) => {
  //         if (res?.data?.success) {
  //           setIsApplied(true);
  //           setReferralMsg(res?.data?.data?.message);
  //           setReferralSuccess(true);
  //           setAppliedReferral(values?.referralCode);
  //         } else {
  //           setIsApplied(false);
  //           setReferralMsg(res?.data?.error);
  //           setReferralSuccess(false);
  //           setAppliedReferral(null);
  //         }
  //         setReferralLoading(false);
  //       })
  //       .catch((err) => {
  //         setIsApplied(false);
  //         setReferralMsg("Something went wrong. Please try again!");
  //         setReferralSuccess(false);
  //         setAppliedReferral(null);
  //         setReferralLoading(false);
  //         alert.error("Something went wrong. Please try again!");
  //       });
  //   };

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={isMobileScreen ? { height: "80vh", overflowY: "scroll" } : {}}
      >
        <Grid item xs={12} style={{ display: "flex" }}>
          {/* <Typography variant="h4" style={{ fontWeight: "500" }}>
            {!prefillData ? "Register with us" : "My Basic Info"}
          </Typography> */}
          {publicText ? (
            <Typography
              variant="h4"
              style={{ fontWeight: "600", textAlign: "left" }}
            >
              {!prefillData ? "Register with us" : "My Basic Info"}
            </Typography>
          ) : (
            <Typography
              variant="h4"
              style={{ fontWeight: "500", textAlign: "center" }}
            >
              {!prefillData ? "Register with us" : "My Basic Info"}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <CustomTextField
            size="small"
            label="Full Name"
            variant="outlined"
            value={values?.name}
            required
            onChange={handleChange("name")}
            error={errorValues?.name ? true : false}
            helperText={errorValues?.name ? errorValues?.name : ""}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <CustomTextField
            size="small"
            label="Official Email ID"
            variant="outlined"
            value={values?.email}
            disabled
            required
            onChange={(e) => {
              setValues({ ...values, email: e.target.value });
              setEmail(e.target.value);
              setErrorValues({ ...errorValues, email: "" });
            }}
            error={errorValues?.email ? true : false}
            helperText={errorValues?.email ? errorValues?.email : ""}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <CustomTextField
            size="small"
            label="Phone Number"
            variant="outlined"
            type="number"
            value={values?.phone}
            required
            onChange={handleChange("phone")}
            error={errorValues?.phone ? true : false}
            helperText={errorValues?.phone ? errorValues?.phone : ""}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12}>
          <Typography variant="body2">Gender</Typography>
          <div style={{ marginTop: "20px" }}>
            <Button
              startIcon={<Male />}
              variant="outlined"
              style={selectedGender.male ? selectgender : disablegender}
              size="small"
              onClick={() => {
                const temp = {
                  male: true,
                  female: false,
                  others: false,
                };
                setSelectedGender({ ...selectedGender, ...temp });
                setGender("Male");
                setErrorValues({ ...errorValues, gender: "" });
              }}
            >
              Male
            </Button>
            <Button
              startIcon={<Female />}
              variant="outlined"
              style={selectedGender.female ? selectgender : disablegender}
              size="small"
              onClick={() => {
                const temp = {
                  male: false,
                  female: true,
                  others: false,
                };
                setSelectedGender({ ...selectedGender, ...temp });
                setGender("Female");
                setErrorValues({ ...errorValues, gender: "" });
              }}
            >
              Female
            </Button>
            <Button
              startIcon={<Transgender />}
              variant="outlined"
              style={selectedGender.others ? selectgender : disablegender}
              size="small"
              onClick={() => {
                const temp = {
                  male: false,
                  female: false,
                  others: true,
                };
                setSelectedGender({ ...selectedGender, ...temp });
                setGender("Others");
                setErrorValues({ ...errorValues, gender: "" });
              }}
            >
              Others
            </Button>
          </div>
          {errorValues?.gender && (
            <Typography
              style={{ color: "#DC3545", fontSize: "13px", marginTop: "5px" }}
            >
              {errorValues?.gender}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <Autocomplete
            id="combo-box-demo"
            options={cityStateList ? cityStateList : []}
            ///value={}
            getOptionLabel={(option) => `${option?.city}, ${option?.state}`}
            onChange={(e, newValue) => {
              if (newValue) {
                setCityState(newValue);
                setErrorValues({ ...errorValues, cityState: "" });
              }
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                size="small"
                label="Select City and State"
                error={errorValues?.cityState ? true : false}
                helperText={
                  errorValues?.cityState ? errorValues?.cityState : ""
                }
              />
            )}
          />
        </Grid>
        {!prefillData && (
          <Grid item xs={12} md={12}>
            <FormControl
              variant="outlined"
              size="small"
              sx={{
                width: "100%",
              }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{
                  fontSize: "14px",
                  color: errorValues?.password ? "#DC3545" : "#7e7e7e",
                  paddingTop: "1.2px",
                }}
              >
                Create Password
              </InputLabel>
              <OutlinedInput
                style={{
                  borderRadius: "8px",
                  height: "40px",
                }}
                // inputProps={{ style: { fontSize: 14 } }}
                // InputLabelProps={{ style: { fontSize: 14 } }}
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                //value={values?.password}
                value={values?.password || ""}
                autoComplete="new-password"
                onChange={handleChange("password")}
                onFocus={() => {
                  onPassWordChange();
                  setShowPassErr(true);
                }}
                onPointerOut={() => {
                  setCheckPwdLength(false);
                  setCheckDigit(false);
                  setCheckNoSpace(false);
                  setCheckCapital(false);
                  setShowPassErr(false);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                //error={errorValues?.password ? true : false}
                //helperText={errorValues?.password ? errorValues?.password : ""}
              />
              {/* <FormHelperText
              style={{
                fontSize: "12px",
                color: "#DC3545",
              }}
              id="standard-weight-helper-text"
              //style={{errorValues?.password && errorPassword}}
            >
              {errorValues?.password ? errorValues?.password : ""}
            </FormHelperText> */}
              {showPassErr && (
                <Paper
                  sx={{
                    padding: "10px",
                    position: "absolute",
                    zIndex: 100000000000,
                    backgroundColor: "#fff",
                    top: "40px",
                    borderRadius: "8px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: 44,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  }}
                >
                  <Typography style={{ marginLeft: "10px" }}>
                    Password must include
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      padding: "5px 15px",
                      color: checkPwdLength ? "#FF0000" : "#34A853",
                    }}
                  >
                    {checkPwdLength ? <ClearIcon /> : <CheckIcon></CheckIcon>}
                    <Typography
                      style={{
                        marginLeft: "10px",
                        color: checkPwdLength ? "#FF0000" : "#34A853",
                      }}
                    >
                      {" "}
                      8 - 20 Characters
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "5px 15px",
                      color: checkCapital ? "#FF0000" : "#34A853",
                    }}
                  >
                    {checkCapital ? <ClearIcon /> : <CheckIcon></CheckIcon>}
                    <Typography
                      style={{
                        marginLeft: "10px",
                        color: checkCapital ? "#FF0000" : "#34A853",
                      }}
                    >
                      At least one capital letter
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "5px 15px",
                      color: checkDigit ? "#FF0000" : "#34A853",
                    }}
                  >
                    {checkDigit ? <ClearIcon /> : <CheckIcon></CheckIcon>}
                    <Typography
                      style={{
                        marginLeft: "10px",
                        color: checkDigit ? "#FF0000" : "#34A853",
                      }}
                    >
                      At least one number
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "5px 15px",
                      color: checkNoSpace ? "#FF0000" : "#34A853",
                    }}
                  >
                    {checkNoSpace ? <ClearIcon /> : <CheckIcon></CheckIcon>}
                    <Typography
                      style={{
                        marginLeft: "10px",
                        color: checkNoSpace ? "#FF0000" : "#34A853",
                      }}
                    >
                      No spaces
                    </Typography>
                  </div>
                </Paper>
              )}
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          {/* {!prefillData ? (
            <>
              <CustomTextField
                size="small"
                label="Enter Referral Code"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <Button
                      color="primary"
                      style={{ fontWeight: "500" }}
                      disabled={
                        values?.referralCode && !isApplied ? false : true
                      }
                      onClick={applyReferral}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          color: `${isApplied ? "#34A853" : "#007BFF"}`,
                          cursor: "pointer",
                        }}
                      >
                        {referralLoading ? (
                          <CircularProgress
                            style={{ color: "#007BFF" }}
                            size={18}
                          />
                        ) : isApplied ? (
                          "Applied"
                        ) : (
                          "Apply"
                        )}
                      </Typography>
                    </Button>
                  ),
                }}
                value={values?.referralCode}
                onChange={(e) => {
                  setValues({
                    ...values,
                    referralCode: e.target.value.toUpperCase(),
                  });
                  setIsApplied(false);
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {referralMsg ? (
                  referralSuccess ? (
                    <CheckCircleRoundedIcon style={{ color: "#34A853" }} />
                  ) : (
                    <HighlightOffIcon style={{ color: "#F1511B" }} />
                  )
                ) : null}

                <Typography
                  variant="body2"
                  style={{
                    color: `${referralSuccess ? "#34A853" : "#F1511B"}`,
                  }}
                >
                  {referralMsg ? referralMsg : ""}
                </Typography>
              </div>
            </>
          ) : (
            <></>
          )} */}
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={continueCheck}
                onChange={(e) => {
                  setContinue(!continueCheck);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <Typography style={{ marginTop: "14px", fontSize: "14px" }}>
                By clicking on continue, you agree to GetWork’s
                <a
                  style={{ color: "#007BFF", fontWeight: "500" }}
                  href="https://getwork.org/terms-and-conditions"
                  target="_blank"
                >
                  Terms & Conditions{" "}
                </a>{" "}
                and{" "}
                <a
                  style={{ color: "#007BFF", fontWeight: "500" }}
                  href="https://getwork.org/privacy-and-cookie-policy"
                  target="_blank"
                >
                  Privacy Policy{" "}
                </a>
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          {" "}
          <Button
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "8px",
              display: "flex",
              fontSize: "15px",
              fontWeight: "400",
              marginBottom: "20px",
            }}
            color="primary"
            variant="contained"
            onClick={signup}
          >
            {isLoading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Continue"
            )}
          </Button>
        </Grid>
        {/* {!prefillData ? (
          <Grid item xs={12} md={12} style={{ display: "grid" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "space-between", 
              }}
              // className={classes.create}
            >
              <Typography variant="body2" style={{ fontFamily: " Nunito" }}>
                Wrong Email?
              </Typography>

              <Button
                size="small"
                style={{ color: "#007bff" }}
                onClick={() => {
                  setStep(1);
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "#007bff", fontWeight: 900 }}
                >
                  Try with different email.
                </Typography>
              </Button>
            </div>
          </Grid>
        ) : (
          <></>
        )} */}
      </Grid>
    </>
  );
}
