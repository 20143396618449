import { CircularProgress } from "@mui/material";
import React from "react";

export default function PageLoader() {
  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <CircularProgress />
      </div>
    </div>
  );
}
