import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  eraseCookie,
  getCookie,
  NIYUKTI_API,
  PRAVESH_API,
} from "../Constants/constants";
export const getCreditDetails = createAsyncThunk(
  "creditDetails/getCreditDetails",
  async ({ typeId, userId, companyId = -1 }) => {
    const url = `${PRAVESH_API}api/common/user_credit_data/?user_type_id=${typeId}&user_id=${userId}&company_id=${companyId}`;
    const res = await axios.get(url, {
      //headers: { Authorization: `Token ${getCookie("user_token_test")}` },
      withCredentials: true,
    });
    return res.data.data;
  }
);

const creditDetailSlice = createSlice({
  name: "creditDetails",
  initialState: {
    creditDetails: {},
    loading: false,
  },
  reducers: {
    logoutCreditDetailData(state, action) {
      state.creditDetails = {};
      state.loading = false;
    },
  },
  extraReducers: {
    [getCreditDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getCreditDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.creditDetails = action?.payload;
    },
    [getCreditDetails.rejected]: (state, action) => {
      state.loading = false;
      eraseCookie("user_id");
      eraseCookie("user_token_test");
      eraseCookie("user_token");
      localStorage.removeItem("loggedIn");
      //   window.location.href = "/";
    },
  },
});

export const { logoutCreditDetailData } = creditDetailSlice.actions;
export default creditDetailSlice.reducer;
