import {
  CircularProgress,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import Countdown from "react-countdown";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import {
  BackendBaseApi,
  emailRegex,
  PRAVESH_API,
} from "../../../Constants/constants";
import { CustomTextField } from "../../Common/InputText/InputField";
import fetchApi from "../../../Apis/fetchApi";

export default function EnterEmailOtp({
  setStep,
  email,
  setEmail,
  checkUser,
  otpEditStep,
  setOtpForgetPwd,
}) {
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [timerComplete, setTimerComplete] = React.useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  const handleChange = (code) => {
    setCode(code);
    setOtpForgetPwd(code);
    setCodeError(null);
  };
  const helper2 = (data) => {
    if (data.success) {
      //setTimerComplete(false);
      setSeconds(30);
    } else {
      if (data?.data?.code === 2) {
        alert.error(data?.data?.code_text);
      }
    }
  };
  const resendOtp = () => {
    fetchApi({
      url: `${PRAVESH_API}api/resend_otp/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        email: email,
        user_type: "company",
      }),
      //setLoading: setLoadingSubmit,
      callBackFn: helper2,
      loginFlow: true,
    });
  };
  // const helper2 = (data) => {
  //   if (data.success) {
  //     //setTimerComplete(false);
  //     setSeconds(30);
  //   } else {
  //     if (data?.data?.code === 2) {
  //       alert.error(data?.data?.code_text);
  //     }
  //   }
  // };
  // const resendOtp = () => {
  //   const body = { email: email };
  //   fetchApi({
  //     url: `${PRAVESH_API}company_saas/login/otp/send/`,
  //     method: "post",
  //     alert: alert,
  //     body: JSON.stringify(body),
  //     loginFlow: true,
  //     alert: alert,
  //   });
  // };

  const helper = (data) => {
    if (data?.data?.code === 3) {
      localStorage.setItem("gw_token", data?.data?.token);
      setCode("");
      setStep(10);
    } else {
      setStep(8);
    }
  };
  const validateDataOtp = () => {
    if (!code) {
      setCodeError("Please Enter Your Verification Code");
      return;
    }
    if (code && code?.length < 4) {
      setCodeError("Please Enter Your Verification Code Correctly");
      return;
    }
    fetchApi({
      url: `${PRAVESH_API}company_saas/login/otp/validate/`,
      method: "post",
      alert: alert,
      body: JSON.stringify({
        email: email,
        otp: parseInt(code),
      }),
      setLoading: setLoadingSubmit,
      callBackFn: helper,
      loginFlow: true,
      alert: alert,
    });

    // setLoadingSubmit(true);
    // axios
    //   .post(`${PRAVESH_API}api/company_saas/login/validate/`, {
    //     email: email,
    //     user_type: "company",
    //     otp: parseInt(code),
    //   })
    //   .then((res) => {
    //     if (res?.data?.success) {
    //       // checkUser(res.data.data.token);
    //       // handleCloseotp();
    //       alert.success(res?.data?.data?.message);
    //       if (res?.data?.data?.code === 1) {
    //         setStep(5);
    //       } else if (res?.data?.data?.code === 3) {
    //         setStep(6);
    //         setEmail(res?.data?.data?.email);
    //       } else if (res?.data?.data?.code === 2) {
    //         checkUser(res?.data?.data?.token, setLoadingSubmit);
    //       } else if (res?.data?.data?.code === 4) {
    //         setStep(7);
    //         // setEmail(res?.data?.data?.email);
    //       }
    //     } else {
    //       alert.error(res?.data?.error);
    //     }
    //   })
    //   .catch((err) => {
    //     throw err;
    //   })
    //   .finally(() => {
    //     setLoadingSubmit(false);
    //   });
  };

  const [seconds, setSeconds] = React.useState(30);

  React.useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          fontWeight: "400",
          marginBottom: "20px",
        }}
      >
        Recover Password
      </Typography>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            fontWeight: "500",
            padding: 12,
          }}
        >
          <span style={{ color: "#707070" }}>Sent to</span>{" "}
          <span style={{ color: "#007bff" }}> {email}</span>
        </Typography>
        <EditIcon
          style={{
            color: "#007bff",
            fontSize: "18px",
            cursor: "pointer",
            marginLeft: "-7px",
          }}
          onClick={() => {
            setStep(8);
          }}
        />
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "400",
                  padding: 6,
                }}
              >
                Enter OTP
              </Typography>
              <OtpInput
                value={code}
                onChange={(e) => handleChange(e)}
                numInputs={4}
                separator={<span style={{ width: "20px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: ".5px solid #a5a5a5",
                  borderRadius: "8px",
                  width: "54px",
                  height: "54px",
                  fontSize: "20px",
                  fontFamily: "gilRoy",
                  // color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: 12,
                }}
              >
                <span style={{ color: "#ed1111" }}> </span>{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  padding: 12,
                }}
              >
                <span
                  style={
                    /* timerComplete */ !seconds
                      ? { color: "#007bff", cursor: "pointer" }
                      : { color: "#707070" }
                  }
                  onClick={() => {
                    if (/* timerComplete */ !seconds) resendOtp();
                  }}
                >
                  Resend OTP
                </span>
                {
                  /* !timerComplete &&  */ seconds ? (
                    <span style={{ color: "#707070" }}>{""}&nbsp; in </span>
                  ) : (
                    <></>
                  )
                }
                <span style={{ color: "#007bff" }}>
                  {
                    /* !timerComplete && */ seconds ? (
                      // <Countdown
                      //   date={Date.now() + 30000}
                      //   onComplete={() => setTimerComplete(true)}
                      //   renderer={(props) => (
                      //     <div>
                      //       <b>{props.seconds + " sec"}</b>
                      //     </div>
                      //   )}
                      // />
                      <div>{seconds + " sec"}</div>
                    ) : (
                      <></>
                    )
                  }
                </span>
              </Typography>
            </div>
          </Grid>
          {/*    <Grid item xs={12} sm={12} style={{ display: "grid" }}>
            <CustomTextField
              size="small"
              label="Enter Code"
              variant="outlined"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                setCodeError("");
              }}
              error={codeError ? true : false}
              helperText={codeError ? codeError : ""}
            />
          </Grid> */}
          <Grid item xs={12} style={{ display: "grid" }}>
            {" "}
            <Button
              variant="contained"
              sx={{
                display: "flex",
                borderRadius: "8px",
                boxShadow: "none",
                height: "40px",
                fontWeight: "400",
                fontSize: "15px",
              }}
              onClick={() => {
                /*  setStep(10); */
                validateDataOtp();
              }}
              color="primary"
            >
              {loadingSubmit ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                " Verify"
              )}
            </Button>
            <Typography
              variant="body2"
              style={{
                color: "#A3A3A3",
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                fontWeight: "500",
                padding: 12,
              }}
            >
              Check spam folders if email is not in the inbox.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
