import {
  combineReducers,
  configureStore,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import userDetailsReducer from "./userDetailSlice";
import selectedPlansReducer from "./selectedPlanSlice";
import profileUnlockReducer from "./profileUnlockSlice";
import availableUnlockReducer from "./availableUnlockSlice";
import creditDetailReducer from "./CreditDetailSlice";
import domainDetailReducer from "./domainDetailSlice";
import notifcationReducer from "./notifcationSlice";
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import thunk from "redux-thunk";

// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage,
// };

// const rootReducer = combineReducers({
//   userDetails: userDetailsReducer,
//   selectedPlans: selectedPlansReducer,
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export default persistStore(
//   configureStore({
//     reducer: persistedReducer,

//     middleware: (getDefaultMiddleware) =>
//       getDefaultMiddleware({
//         serializableCheck: {
//           ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//         },
//       }).concat(createAsyncThunk),
//   })
// );

export default configureStore({
  reducer: {
    userDetails: userDetailsReducer,
    selectedPlans: selectedPlansReducer,
    profileUnlock: profileUnlockReducer,
    availableUnlocks: availableUnlockReducer,
    creditDetails: creditDetailReducer,
    domainDetail: domainDetailReducer,
    notification: notifcationReducer,
  },
});
