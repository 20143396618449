import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useHistory, useLocation } from "react-router-dom";
import fetchApi from "./Apis/fetchApi";
import AlertTemplate from "./Components/Common/Theme/AlertTemplate";
import PrimaryTheme from "./Components/Common/Theme/PrimaryTheme";
import { PRAVESH_API } from "./Constants/constants";
import useCheckMobileScreen from "./Hooks/useCheckMobileScreen";
import { addDomainDetail } from "./Redux/domainDetailSlice";
import { getUserDetails } from "./Redux/userDetailSlice";

import Routes from "./Routes/allRoutes";
// import { ReactComponent as NoData } from "../src/Assets/Images/svg/SmallScreen.svg"; //../../Assets/Images/svg/NoPostJobFound.svg

function App() {
  const theme_change = null;
  const dispatch = useDispatch();
  const options = {
    position: positions.TOP_RIGHT,
    timeout: 5000,
    offset: "30px",
    transition: transitions.SCALE,
    containerStyle: {
      zIndex: 10001,
      fontSize: "14px",
      textTransform: "lowercase",
    },
  };
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [domainSuccess, setDomainSucess] = useState(false);
  const isMobileScreen = useCheckMobileScreen();
  const [desktopView, setDesktopView] = useState(false);

  const helper = (data) => {
    //console.log("DATATAATATA", data?.data?.user_dict);
    const data1 = {
      ...data,
      pathname: "/",
      fullName: data?.data?.user_dict?.full_name,
      email: data?.data?.user_dict?.email,
      companyId: data?.data?.user_dict?.company,
      adminDesignation: data?.data?.user_dict?.designation,
      userRole: data?.data?.user_dict?.user_role,
      companyName: data?.data?.user_dict?.company_name,
      userRoleId: data?.data?.user_dict?.role_id,
      userTypeId: data?.data?.user_dict?.type_id,
      step: 14,
      code: data?.data?.user_dict?.code,
      keyCode: data?.data?.user_dict?.key,
    };
    history.push(data1);
  };

  const checkValidRoute = (url) => {
    /*   console.log("Enter index page ", url); */

    if (url === "team-invite") {
      var code = location?.search?.slice(1)?.split("&")[0]?.split("=")[1];
      var key = location?.search?.slice(1)?.split("&")[1]?.split("=")[1];

      let body = {
        code: code,
        key: key,
      };
      fetchApi({
        url: `${PRAVESH_API}company_saas/dashboard/team/invite/verify/`,
        method: "post",
        //alert: alert,
        body: JSON.stringify(body),
        //setLoading: setLoading,
        callBackFn: helper,
      });
    }
  };

  useEffect(() => {
    checkValidRoute(location?.pathname.split("/")[1]);
  }, [location?.pathname]);

  const domainDetail = useSelector((state) => state?.domainDetail?.data);
  const errorCallback = (data) => {
    setDomainSucess(false);
    if (data) history.push("/not-authorized");
  };
  const domainHelper = (data) => {
    setDomainSucess(true);
    let obj = {
      ...data?.data,
      domainSuccess: true,
      primaryColorMain: data?.data?.primary_color,
      secondaryColorMain: data?.data?.secondary_color,
    };
    dispatch(addDomainDetail({ domainDetailData: obj /* data?.data  */ }));
  };
  const getDomainDetail = () => {
    fetchApi({
      url: `${PRAVESH_API}company_saas/company/domain_details/`,
      method: "get",
      //alert: alert,
      setLoading: setLoading,
      setResponse: domainHelper,
      setError: errorCallback,
      checkDomain: true,
    });
  };

  useEffect(() => {
    if (!Object.keys(domainDetail).length > 0) {
      getDomainDetail();
    }
  }, [domainDetail]);

  useEffect(() => {
    if (Object.keys(domainDetail)?.length > 0 && !domainDetail?.domainSuccess) {
      //console.log("SDFGHJKLFGHJKL", domainDetail);
      history.push("/not-authorized");
    }
  }, [domainDetail]);

  // useEffect(() => {
  //   console.log("desktopView", desktopView);
  // }, [desktopView]);
  const LOGGEDIN = useSelector((state) => state?.userDetails?.LOGGEDIN);
  const userDetailLoading = useSelector((state) => state?.userDetails?.loading);
  useEffect(() => {
    if (/* localStorage.getItem("isLoggedin") === "true" && */ !LOGGEDIN) {
      dispatch(getUserDetails());
    }
  }, [LOGGEDIN]);

  return (
    <>
      <Helmet>
        <title>
          {
            /* Object.keys(domainDetail).length > 0 */ domainDetail?.company_name
              ? domainDetail?.company_name
              : "GetWork"
          }
        </title>
        <meta
          name="description"
          content={
            /* Object.keys(domainDetail).length > 0 */
            domainDetail?.company_name ? domainDetail?.company_name : "GetWork"
          }
        />

        {!desktopView ? (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
        ) : (
          <meta name="viewport" content={"width=1280"} />
        )}
        <link
          rel="icon"
          type="image/png"
          href={
            /*   Object.keys(domainDetail).length > 0 */
            domainDetail?.favicon
            /* ? domainDetail?.favicon
              : "%PUBLIC_URL%/favicon.ico?v=2" */
          }
          sizes="16x16"
        />
      </Helmet>
      <PrimaryTheme theme_change={theme_change}>
        <AlertProvider template={AlertTemplate} {...options}>
          <Switch>
            {loading || userDetailLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100vw",
                  height: "80vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Routes setDesktopView={setDesktopView} />
            )}
          </Switch>
        </AlertProvider>
      </PrimaryTheme>
    </>
  );
}

export default App;
