import { Button, Typography } from "@mui/material";
import React from "react";
import domainError from "../../Assets/Images/png/domainError.png";
const DomainDetailError = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        //flexDirection: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img src={domainError} height="400px" />
        <Typography variant="h2" sx={{ marginTop: "0px", textAlign: "center" }}>
          Not Authorized
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            Our
          </Typography>

          <Typography
            color="primary"
            style={{ marginTop: "10px", marginLeft: "5px", cursor: "pointer" }}
            onClick={() => {
              window.open("http://getwork.org/employer");
            }}
          >
            Homepage
          </Typography>
          <Typography style={{ marginTop: "10px", marginLeft: "5px" }}>
            is good place to get started.
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{
            boxShadow: "none",
            height: "40px",
            borderRadius: "8px",
            mt: 5,
          }}
          onClick={() => {
            window.open("http://getwork.org/employer");
          }}
        >
          Go To Home Page
        </Button>
      </div>
    </div>
  );
};

export default DomainDetailError;
