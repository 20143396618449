import { createSlice } from "@reduxjs/toolkit";

const domainDetailSlice = createSlice({
  name: "domainDetail",
  initialState: {
    data: {},
  },
  reducers: {
    addDomainDetail(state, action) {
      state.data = action?.payload?.domainDetailData;
    },

    deleteDomainDetail: (state, action) => {
      state.data = {};
    },
  },
});

export const { addDomainDetail, deleteDomainDetail } =
  domainDetailSlice.actions;
export default domainDetailSlice.reducer;
