import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import logo from "../../Assets/Images/png/getwork-logo.png";
import RegisterCompany from "../../Components/Dialog/Job/RegisterCompany";
import companyLogo from "../../Assets/Images/png/companyLogo.png";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function PublicLayout({
  children,
  logoData = null,
  companyWebsite = null,
  setEmail,
  email,
  step,
  setPhoneNo,
  phoneNo,
  job_id,
  handleClickOpenSuccesfully,
  jobTitle,
  companyName,
}) {
  const domainDetail = useSelector((state) => state?.domainDetail?.data);
  const location = useLocation();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
          borderBottom: "1px solid #B0B6BA",
        }}
      >
        <Toolbar sx={{ backgroundColor: "#fff" }}>
          <Container maxWidth="xl">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: 130,
                  height: 60,
                  borderRadius: "8px",
                }}
              >
                <img
                  style={{
                    margin: "auto",
                    //  display: "block",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  alt="complex"
                  src={
                    logoData
                      ? logoData
                      : companyWebsite
                      ? `//logo.clearbit.com/${companyWebsite}`
                      : domainDetail?.logo /* logo */
                  }
                  onError={(e) => {
                    e.target.onerror = () => null;
                    e.target.src = companyLogo;
                  }}
                />
              </div>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box>{children}</Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderTop: "1px solid #B0B6BA",
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        }}
      >
        <Toolbar sx={{ backgroundColor: "#fff" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h6">Powered By</Typography>
            <div
              style={{
                display: "flex",
                width: 130,
                height: 60,
                borderRadius: "8px",
              }}
            >
              <img
                style={{
                  margin: "auto",
                  //  display: "block",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                alt="complex"
                src={logo}
              />
            </div>
          </Box>
        </Toolbar>
      </Box>
      {!["/open-jobs", "/job-detail"]?.includes(location?.pathname) && (
        <AppBar
          position="fixed"
          color="primary"
          sx={{
            top: "auto",
            bottom: 0,
            [theme.breakpoints.up("sm")]: {
              display: "none",
            },
          }}
        >
          <Toolbar sx={{ justifyContent: "center" }}>
            {!sessionStorage.getItem("jobApplied") ? (
              <Button
                variant="contained"
                color="primary"
                sx={{ boxShadow: "none" }}
                onClick={handleClickOpen}
              >
                Apply Now
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{ boxShadow: "none" }}
                onClick={() => {
                  window.open("https://getwork.org/public/jobs", "_blank");
                }}
              >
                Explore Now
              </Button>
            )}
          </Toolbar>
        </AppBar>
      )}
      <RegisterCompany
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        setEmail={setEmail}
        email={email}
        currentStep={4}
        setPhoneNo={setPhoneNo}
        phoneNo={phoneNo}
        job_id={job_id}
        handleClickOpenSuccesfully={handleClickOpenSuccesfully}
        jobTitle={jobTitle}
        companyName={companyName}
      />
    </>
  );
}

export default PublicLayout;
