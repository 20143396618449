import { Switch, styled } from "@mui/material";
export const NotificationSwitch = styled((props) => (
  <Switch
    /* focusVisibleClassName=".Mui-focusVisible" */ disableRipple
    {...props}
    value={props?.value}
    onChange={props?.onChange}
    checked={props?.checked}
  />
))(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      left: 16,
    },
    "&:after": {
      right: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 20,
    height: 20,
    // margin: 2,
  },
}));
