import { createSlice } from "@reduxjs/toolkit";

const selectedPlanSlice = createSlice({
  name: "selectedPlan",
  initialState: {
    //selectedPlans: {},
    banners: [],
    hireForSurePlanDetails: {},
    subscriptionSlotDetails: {},
    slot_list: {},
    plan_list: [],
    buttonClicked: "",
    subscriptionDuration: "monthly",
    cardSelected: null,
    apiCallMade: false,
  },
  reducers: {
    addPlans(state, action) {
      state.banners = action?.payload?.banners;
      state.slot_list = action?.payload?.slotList;
      state.plan_list = action?.payload?.planList;
      state.buttonClicked = action?.payload?.buttonClicked;
      state.hireForSurePlanDetails = action?.payload?.hireForSurePlanDetails;
      state.subscriptionSlotDetails = action?.payload?.subscriptionSlotDetails;
      state.apiCallMade = action?.payload?.apiCallMade;
    },

    deletePlans: (state, action) => {
      state.selectedPlans = {};
      state.buttonClicked = "";
    },
    resetSelections: (state, action) => {
      const list = { ...state.slot_list };
      Object.keys(list)?.forEach((item) => {
        list[item]?.forEach((item2) => {
          item2["slots"] = 0;
        });
        state.slot_list = list;
        state.subscriptionDuration = "monthly";
        state.cardSelected = null;
      });
    },

    updateBookSlotCount: (state, action) => {
      if (action?.payload?.type === "internHiring") {
        state.slot_list["Intern hiring"][action?.payload?.index]["slots"] =
          state.slot_list["Intern hiring"][action?.payload?.index]["slots"]
            ? state.slot_list["Intern hiring"][action?.payload?.index][
                "slots"
              ] + 1
            : 1;
      } else if (action?.payload?.type === "nonTechHiring") {
        state.slot_list["Non-Tech hiring"][action?.payload?.index]["slots"] =
          state.slot_list["Non-Tech hiring"][action?.payload?.index]["slots"]
            ? state.slot_list["Non-Tech hiring"][action?.payload?.index][
                "slots"
              ] + 1
            : 1;
      } else if (action?.payload?.type === "techHiring") {
        state.slot_list["Tech hiring"][action?.payload?.index]["slots"] = state
          .slot_list["Tech hiring"][action?.payload?.index]["slots"]
          ? state.slot_list["Tech hiring"][action?.payload?.index]["slots"] + 1
          : 1;
      }
    },

    decrementBookSlotCount: (state, action) => {
      if (action?.payload?.type === "internHiring") {
        state.slot_list["Intern hiring"][action?.payload?.index]["slots"] =
          state.slot_list["Intern hiring"][action?.payload?.index]["slots"]
            ? state.slot_list["Intern hiring"][action?.payload?.index][
                "slots"
              ] - 1
            : 0;
      } else if (action?.payload?.type === "nonTechHiring") {
        state.slot_list["Non-Tech hiring"][action?.payload?.index]["slots"] =
          state.slot_list["Non-Tech hiring"][action?.payload?.index]["slots"]
            ? state.slot_list["Non-Tech hiring"][action?.payload?.index][
                "slots"
              ] - 1
            : 0;
      } else if (action?.payload?.type === "techHiring") {
        state.slot_list["Tech hiring"][action?.payload?.index]["slots"] = state
          .slot_list["Tech hiring"][action?.payload?.index]["slots"]
          ? state.slot_list["Tech hiring"][action?.payload?.index]["slots"] - 1
          : 0;
      }
    },
    updateButtonClicked: (state, action) => {
      state.buttonClicked = action?.payload?.buttonClicked;
    },
    updateCardSelected: (state, action) => {
      state.cardSelected = action?.payload?.cardSelected;
    },
    updateSubcriptionDuration: (state, action) => {
      state.subscriptionDuration = action?.payload?.subscriptionDuration;
    },
  },
});

export const {
  addPlans,
  deletePlans,
  resetSelections,
  updateBookSlotCount,
  decrementBookSlotCount,
  updateButtonClicked,
  updateCardSelected,
  updateSubcriptionDuration,
} = selectedPlanSlice.actions;
export default selectedPlanSlice.reducer;
