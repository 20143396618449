import { Box, Typography, alpha, useTheme, Button } from "@mui/material";
import React from "react";

import { useHistory, Link } from "react-router-dom";
import fetchApi from "../../../Apis/fetchApi";
import { SANDESH_API } from "../../../Constants/constants";

function AllNotificationCards({ data }) {
  const theme = useTheme();
  const history = useHistory();
  const markAsRead = () => {
    fetchApi({
      url: `${SANDESH_API}api/company/notifications/mark-read/`,
    });
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: data?.is_read
            ? "#EBEBEB"
            : alpha(theme.palette.primary.main, 0.1),
          p: 2,
          pt: 1,
          pb: 1,
          borderRadius: "8px",
          mt: 2,
          mb: 2,
          cursor: "pointer",
        }}
        onClick={() => {
          markAsRead();
          history.push(data?.redirect_end_point);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
            {data?.title ? data?.title : ""}
          </Typography>

          <Typography
            sx={{ fontSize: "10px", fontWeight: "400", color: "#979797" }}
          >
            {data?.notification_date}
          </Typography>
        </div>
        <Typography sx={{ color: "#6C757D", fontSize: "12px" }}>
          {data?.final_message}
          {/* New Applicant has applied for{" "}
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
          >
            {" "}
            Software Engineering{" "}
          </span>{" "}
          2 Job */}
        </Typography>
        {/* <div>
          <Button
            variant="text"
            color="primary"
            sx={{
              p: 0,
              fontSize: "12px",
              ml: "-13px",
              fontWeight: "600",
            }}
          >
            Accept
          </Button>

          <Button
            variant="text"
            color="primary"
            sx={{
              p: 0,
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            Reject
          </Button>
        </div> */}
      </Box>
    </>
  );
}

export default AllNotificationCards;
