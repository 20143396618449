import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";
import fetchApi from "../../../Apis/fetchApi";
import { PRAVESH_API } from "../../../Constants/constants";
import { CustomTextField } from "../../Common/InputText/InputField";

export default function RegisterApplyJob({
  setStep,
  email,
  setOtpEditStep,
  setEmail,
  data,
  prefillData,
  handleClose,
  getUser,
}) {
  // const classes = useStyles();
  const theme = useTheme();
  const selectgender = {
    borderColor: theme.palette.primary.main,
    backgroundColor: "#e8f3ff",
    color: theme.palette.primary.main,
    borderRadius: "8px",
    textTransform: "capitalize",
    marginRight: "20px",
  };
  const disablegender = {
    borderColor: theme.palette.grey[500],
    backgroundColor: "#fff",
    color: theme.palette.grey[500],
    borderRadius: "8px",
    textTransform: "capitalize",
    marginRight: "20px",
  };
  const uploadpaper = {
    cursor: "pointer",
    boxShadow: "none",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "8px",
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  };

  const ref = useRef();
  const [resume, setResume] = React.useState(null);

  const handleFile = (e) => {
    setResume(e.target.files[0]);
  };

  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [appliedReferral, setAppliedReferral] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = React.useState({
    experienced: null,
    totalExp: null,
    currentCtc: null,
    expectedCtc: null,
    confidential: null,
    negotiable: null,
    noticePeriod: null,
  });

  const [errorValues, setErrorValues] = React.useState({
    experienced: null,
    totalExp: null,
    currentCtc: null,
    expectedCtc: null,
    confidential: null,
    negotiable: null,
    noticePeriod: null,
  });

  const validateForm = () => {
    let isError = false;
    let experiencedError = "";
    let totalExpError = "";
    let currentCtcError = "";
    let expectedCtcError = "";
    let resumeError = "";
    let noticePeriodError = "";

    if (!values?.experienced) {
      experiencedError = "Please select the experience level";
      isError = true;
    }
    if (values?.experienced === "experienced" && !values?.totalExp) {
      totalExpError = "Please enter the total experience";
      isError = true;
    }
    if (values?.experienced === "experienced" && !values?.currentCtc) {
      currentCtcError = "Please enter the current ctc";
      isError = true;
    }
    if (values?.experienced === "experienced" && !values?.expectedCtc) {
      expectedCtcError = "Please enter the expected ctc";
      isError = true;
    }
    if (values?.experienced === "experienced" && !values?.noticePeriod) {
      noticePeriodError = "Please enter the notice period";
      isError = true;
    }
    if (!resume) {
      resumeError = "Please attach the resume";
      isError = true;
    }

    setErrorValues({
      experienced: experiencedError,
      totalExp: totalExpError,
      currentCtc: currentCtcError,
      expectedCtc: expectedCtcError,
      resume: resumeError,
      noticePeriod: noticePeriodError,
    });

    return isError ? false : true;
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signup = () => {
    const helper = (data) => {
      //console.log(data);
      getUser(localStorage.getItem("token"));
      // if (data.data.rd_code === 20) {
      //   setStep(3);
      //   setOtpEditStep(1);
      // } else {
      //   //setCookie("user_token", data?.data?.token, 1);
      //   history.push("/admin/dashboard");
      //   //user updated
      //   //setStep(12);
      // }
    };

    if (validateForm()) {
      // if (values?.currentCtc && values?.currentCtc?.isInteger()) {
      //   alert?.error("Please enter ctc in Inr");
      //   return;
      // }
      let fd = new FormData();
      if (values?.experienced === "fresher") {
        resume && fd.append("resume", resume);
      } else {
        resume && fd.append("resume", resume);
        fd.append("expected_ctc", values?.expectedCtc);
        fd.append("cur_ctc", values?.currentCtc); //total_exp
        fd.append("total_exp", values?.totalExp);
        fd.append(
          "salary_validation",
          JSON.stringify({
            is_negotialble: values?.negotiable ? values?.negotiable : false,
            is_confidential: values?.confidential
              ? values?.confidential
              : false,
          })
        );
      }
      const headers = JSON.stringify({
        //headers: { Authorization: `Token ${localStorage.getItem("token")}` },
        withCredentials: true,
      });
      fetchApi({
        url: `${PRAVESH_API}api/getwork/work-details/`,
        method: "post",
        alert: alert,
        headers: headers,
        formData: fd,
        setLoading: setIsLoading,
        callBackFn: helper,
        //loginFlow: true,
      });
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid md={12}>
          {" "}
          <Typography
            variant="h4"
            style={{
              fontWeight: "500",
              //textAlign: "center",
              marginBottom: "-10px",
            }}
          >
            What is Your Current Experience ?
          </Typography>
        </Grid>

        <Grid md={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  value="fresher"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      experienced: e.target.value,
                    });
                    setErrorValues({
                      experienced: null,
                      totalExp: null,
                      currentCtc: null,
                      expectedCtc: null,
                      confidential: null,
                      negotiable: null,
                      noticePeriod: null,
                    });
                  }}
                  //onChange={handleQualification}
                  checked={values?.experienced === "fresher"}
                />
              }
              label={<Typography sx={{ fontSize: "14px" }}>Fresher</Typography>}
            />
          </FormGroup>
          {errorValues?.experienced && (
            <Typography
              style={{
                color: "#DC3545",
                fontSize: "13px" /* marginTop: "5px" */,
              }}
            >
              {errorValues?.experienced}
            </Typography>
          )}
        </Grid>
        <Grid md={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  value="experienced"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      experienced: e.target.value,
                    });
                    setErrorValues({
                      experienced: null,
                      totalExp: null,
                      currentCtc: null,
                      expectedCtc: null,
                      confidential: null,
                      negotiable: null,
                      noticePeriod: null,
                    });
                  }}
                  //onChange={handleQualification}
                  checked={values?.experienced === "experienced"}
                />
              }
              label={
                <Typography sx={{ fontSize: "14px" }}>Experienced</Typography>
              }
            />
          </FormGroup>
        </Grid>
        {values?.experienced === "experienced" ? (
          <>
            <Grid xs={12} sm={12} md={12} sx={{ display: "grid" }}>
              <CustomTextField
                size="small"
                label="Total Experience (in years)"
                error={errorValues?.totalExp ? true : false}
                helperText={errorValues?.totalExp ? errorValues?.totalExp : ""}
                value={values?.totalExp}
                type="number"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => {
                  setValues({
                    ...values,
                    totalExp: parseInt(e.target.value),
                  });
                  setErrorValues({ ...errorValues, totalExp: "" });
                }}
              />
            </Grid>
            <Grid xs={6} sm={6} md={6} sx={{ display: "grid" }}>
              <CustomTextField
                size="small"
                label="Current CTC(INR)"
                type="number"
                onWheel={() => document.activeElement.blur()}
                error={errorValues?.currentCtc ? true : false}
                helperText={
                  errorValues?.currentCtc ? errorValues?.currentCtc : ""
                }
                value={values?.currentCtc}
                onChange={(e) => {
                  setValues({
                    ...values,
                    currentCtc: parseInt(e.target.value),
                  });
                  setErrorValues({ ...errorValues, currentCtc: "" });
                }}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      value={true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValues({
                            ...values,
                            confidential: true,
                          });
                        } else {
                          setValues({
                            ...values,
                            confidential: false,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "14px" }}>
                      Confidential
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid xs={6} sm={6} md={6} sx={{ display: "grid" }}>
              <CustomTextField
                size="small"
                label="Expected CTC(INR)"
                type="number"
                onWheel={() => document.activeElement.blur()}
                error={errorValues?.expectedCtc ? true : false}
                helperText={
                  errorValues?.expectedCtc ? errorValues?.expectedCtc : ""
                }
                value={values?.expectedCtc}
                onChange={(e) => {
                  setValues({
                    ...values,
                    expectedCtc: parseInt(e.target.value),
                  });
                  setErrorValues({ ...errorValues, expectedCtc: "" });
                }}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValues({
                            ...values,
                            negotiable: true,
                          });
                        } else {
                          setValues({
                            ...values,
                            negotiable: false,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "14px" }}>
                      Negotiable
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid xs={12} sm={12} md={12} sx={{ display: "grid" }}>
              <CustomTextField
                size="small"
                label="Notice Period (in days)"
                type="number"
                onWheel={() => document.activeElement.blur()}
                error={errorValues?.noticePeriod ? true : false}
                helperText={
                  errorValues?.noticePeriod ? errorValues?.noticePeriod : ""
                }
                value={values?.noticePeriod}
                onChange={(e) => {
                  setValues({
                    ...values,
                    noticePeriod: parseInt(e.target.value),
                  });
                  setErrorValues({ ...errorValues, noticePeriod: "" });
                }}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}

        <Grid xs={12} sm={12} md={12}>
          <Box
            style={uploadpaper}
            onClick={() => {
              // `current` points to the mounted file input element
              ref.current.click();
            }}
          >
            <div
              style={{
                border: "1px solid",
                borderColor: "#D7E6F0",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill={theme.palette.primary.main}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.958 26.166V11.249L14.5 15.666L13.042 14.166L20 7.20703L26.958 14.166L25.5 15.666L21.042 11.249V26.166H18.958ZM10.125 32.499C9.403 32.499 8.785 32.242 8.271 31.728C7.757 31.214 7.5 30.596 7.5 29.874V24.957H9.583V29.874C9.583 30.0127 9.63867 30.1377 9.75 30.249C9.86133 30.3604 9.98633 30.416 10.125 30.416H29.875C30.0137 30.416 30.1387 30.3604 30.25 30.249C30.3613 30.1377 30.417 30.0127 30.417 29.874V24.957H32.5V29.874C32.5 30.596 32.243 31.214 31.729 31.728C31.215 32.242 30.597 32.499 29.875 32.499H10.125Z"
                  fill={theme.palette.primary.main}
                />
              </svg>
            </div>
            <div style={{ marginLeft: "20px" }}>
              <Typography
                variant="h6"
                color="primary"
                caption="The year when you have completed your highest qualification."
              >
                Upload Resume
              </Typography>
              <input ref={ref} type="file" onChange={handleFile} hidden />

              <Typography variant="body2" color="secondary">
                {" "}
                {!resume ? (
                  <>Accepted file formats: .pdf &.doc</>
                ) : (
                  <Typography noWrap style={{ maxWidth: "250px" }}>
                    {"Selected File: " + resume?.name}
                  </Typography>
                )}
              </Typography>
            </div>
          </Box>
          {errorValues?.resume && (
            <Typography
              style={{ color: "#DC3545", fontSize: "13px", marginTop: "5px" }}
            >
              {errorValues?.resume}
            </Typography>
          )}
        </Grid>
        <Grid
          xs={6}
          sm={12}
          md={12}
          sx={{
            display: "grid",
            [theme.breakpoints.up("sm")]: { display: "none" },
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "8px",
              boxShadow: "none",
              height: "40px",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={6} sm={12} md={12} md={12} style={{ display: "grid" }}>
          {" "}
          <Button
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "8px",
              display: "flex",
              fontSize: "15px",
              fontWeight: "400",
            }}
            color="primary"
            variant="contained"
            onClick={signup}
          >
            {isLoading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Apply"
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
