import {
  Button,
  Divider,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import {
  emailRegex,
  BackendBaseApi,
  PRAVESH_API,
} from "../../../Constants/constants";
import logo from "../../../Assets/Images/png/getwork-logo.png";
import axios from "axios";
import { useAlert } from "react-alert";
import { alpha, styled } from "@mui/material/styles";
import { CustomTextField } from "../../Common/InputText/InputField";
import fetchApi from "../../../Apis/fetchApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const googleicon = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9995 12.5151C23.9995 11.5083 23.9178 10.7736 23.741 10.0117H12.2441V14.556H18.9925C18.8565 15.6853 18.1218 17.386 16.4891 18.5288L16.4662 18.681L20.1013 21.497L20.3531 21.5221C22.6661 19.386 23.9995 16.2431 23.9995 12.5151Z"
      fill="#4285F4"
    />
    <path
      d="M12.2457 24.4913C15.5518 24.4913 18.3273 23.4028 20.3546 21.5252L16.4906 18.5319C15.4566 19.253 14.0688 19.7564 12.2457 19.7564C9.00752 19.7564 6.25919 17.6204 5.27949 14.668L5.13588 14.6802L1.35607 17.6054L1.30664 17.7428C3.32026 21.7429 7.45641 24.4913 12.2457 24.4913Z"
      fill="#34A853"
    />
    <path
      d="M5.27896 14.6656C5.02046 13.9037 4.87085 13.0873 4.87085 12.2438C4.87085 11.4002 5.02046 10.5839 5.26536 9.82199L5.25851 9.65972L1.43133 6.6875L1.30612 6.74706C0.476205 8.40698 0 10.271 0 12.2438C0 14.2166 0.476205 16.0806 1.30612 17.7405L5.27896 14.6656Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2457 4.73475C14.545 4.73475 16.096 5.72796 16.9804 6.55797L20.4362 3.18373C18.3138 1.21091 15.5518 0 12.2457 0C7.45641 0 3.32026 2.74833 1.30664 6.74838L5.26588 9.82331C6.25919 6.87087 9.00752 4.73475 12.2457 4.73475Z"
      fill="#EB4335"
    />
  </svg>
);

export default function RecoverEmailPassword({
  setStep,
  email,
  setEmail,
  responseGoogle,
  setOtpEditStep,
  from,
}) {
  // const classes = useStyles();
  const [emailError, setEmailError] = useState("");
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);

  const helper = (data) => {
    if (data?.data?.code === 3) {
      setStep(9);
    }
  };

  function onContinue() {
    if (!email || !emailRegex.test(email)) {
      setEmailError("Please enter valid email address");
      return;
    }
    const body = { email: email };
    fetchApi({
      url:
        from === "publicJobInvite"
          ? `${PRAVESH_API}company_saas/login/otp/send/`
          : `${PRAVESH_API}company_saas/login/otp/send/`,
      method: "post",
      alert: alert,
      body: JSON.stringify(body),
      setLoading: setLoading,
      callBackFn: helper,
      loginFlow: true,
      alert: alert,
    });

    // setLoading(true);
    // axios
    //   .post(`${PRAVESH_API}api/company_saas/continue/`, body, {
    //     withCredentials: true,
    //   })
    //   .then((res) => {
    //     if (res?.data?.success) {
    //       alert.success(res?.data?.data?.message);
    //       if (res?.data?.data?.code === 1) {
    //         setStep(4);
    //       } else if (res?.data?.data?.code === 2) {
    //         setStep(2);
    //       } else if (res?.data?.data?.code === 3) {
    //         setOtpEditStep(1);
    //         setStep(3);
    //       }
    //     } else {
    //       alert.error(res?.data?.error);
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     alert.error("Some error occurred. Please try again!");
    //     throw err;
    //   });
  }

  return (
    <>
      {/* <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Typography variant="h4" style={{ fontWeight: "500" }}>
          Recover Password
        </Typography>
      </div> */}
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute" }}>
          <IconButton
            onClick={() => {
              setStep(2);
            }}
          >
            <ArrowBackIcon sx={{ color: "#000" }} />
          </IconButton>
        </div>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "20px", marginTop: "8px" }}
      >
        <Typography variant="h4" style={{ fontWeight: "500" }}>
          Recover Password
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <CustomTextField
            label="Enter Your Official Email ID "
            variant="outlined"
            size="small"
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            error={emailError !== "" ? true : false}
            helperText={
              emailError ? (
                <span style={{ color: "#D64045", fontSize: 13 }}>
                  {emailError}
                </span>
              ) : (
                ""
              )
            }
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "grid" }}>
          <Button
            color="primary"
            sx={{
              height: "40px",
              boxShadow: "none",
              borderRadius: "8px",
              display: "flex",
              fontWeight: "400",
              fontSize: "15px",
            }}
            variant="contained"
            onClick={() => {
              onContinue();
              /* setStep(9); */
            }}
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Send Code"
            )}
          </Button>
          <Typography
            variant="body2"
            style={{
              color: "#A3A3A3",
              textAlign: "center",
              marginTop: "20px",
              fontWeight: "500",
              padding: 12,
            }}
          >
            Check spam folders if email is not in the inbox.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
