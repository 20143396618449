import React from "react";
import ReactDOM from "react-dom/client";
import "./Assets/Styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import errorsvg from "../src/Assets/Images/svg/No-data-rafiki.svg"; //../src/assets/svg/No-data-rafiki.svg"
import { Button, makeStyles, Typography } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img src={errorsvg} height="400px" width="400px" alt="404 Error" />
      <Typography
        variant="h1"
        style={{ fontWeight: "bold", marginBottom: "20px" }}
      >
        Something went Wrong
      </Typography>
      {/* <pre>{error.message}</pre> */}
      <br />
      <br />
      <Button
        style={{
          //borderRadius: "30px",
          borderRadius: "8px",
          boxShadow: "none",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
        variant="contained"
        color="primary"
        // onClick={resetErrorBoundary}
        onClick={() => window.location.reload()}
      >
        Try Again
      </Button>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
