import configs from "../Config/config";

export const BackendBaseApi = {
  PRAVESH: configs.apis.pravesh,
  NIYUKTI: configs.apis.niyukti,
  SANCHALAN: configs.apis.sanchalan,
  CMS: configs.apis.cms,
};

// Backend apis url domains
export const NIYUKTI_API = process.env.REACT_APP_NIYUKTI_API;
export const NIYUKTI_API_2 = process.env.REACT_APP_NIYUKTI_API2;
export const PRAVESH_API = process.env.REACT_APP_PRAVESH_API;
export const SUJHAV_API = process.env.REACT_APP_SUJHAV_API;
export const NIYOJAN_ENDPOINT = process.env.REACT_APP_NIYOJAN;
export const SANDESH_API = process.env.REACT_APP_SANDESH;
export const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GETWORK_ORG_DOMAIN = process.env.REACT_APP_GETWORK_ORG;

// export const APP_ENDPOINT = configs.app;
export const NEXT_APP_ENDPOINT = configs.next_app;

// export const MENTORKART_ENDPOINT = configs.mentorkart;
// export const FACEBOOK_APP_ID = configs.facebookAppId;

// export const FACEBOOK_APP_SECRET = configs.facebookClientSecret;

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
export const websiteRegex =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
export const websiteRegexNew =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

export const IndianNumberRegex = /^[0]?[6789]\d{9}$/;
const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (email) => {
  return String(email).toLowerCase().match(emailRe);
};

export const checkForToken = (error, history, location, alert) => {
  if (error?.response?.status === 401) {
    history.push({ pathname: "/", search: `?source=${location.pathname}` });
    // alert.error("Session Expired");
    throw error;
  }
  if (error?.response?.status === 403) {
    //history.push({ pathname: "/" });
    eraseCookie("user_id");
    window.location.href = "/";
    alert.error(error.response.data.error);
    throw error;
  }
};

export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export default function generateCsv(arr, name) {
  let str = "";
  arr.forEach((item, index) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item[name] + ", ")
      : (str += item[name])
  );
  return str;
}

export function is_url(str) {
  const regexp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
  if (str !== "" && regexp.test(str)) {
    return true;
  } else {
    return false;
  }
}
export function generateCsvWithoutSpacing(arr, name) {
  let str = "";
  arr.forEach((item, index) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item[name] + ",")
      : (str += item[name])
  );
  return str;
}

export function generateCsvNew(arr) {
  let str = "";
  if (arr) {
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1
        ? (str += item + ", ")
        : (str += item)
    );
  }
  str = str.replace(/,\s*$/, "");
  return str;
}
export function generateStringWithoutComma(arr) {
  let str = "";
  if (arr) {
    arr.forEach((item, index) =>
      arr.indexOf(item) !== arr.length - 1 ? (str += item) : (str += item)
    );
  }
  str = str.replace(/,\s*$/, "");
  return str;
}
export function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}

export const blockedEmails = [
  "@zohomail.in",
  "@proton.me",
  "@protonmail.com",
  "gmail.com",
  "email.com",
  "@yahoo.com.",
  "@myyahoo.com",
  "@hotmail.com",
  "@live.com",
  "@msn.com",
  "@aol.com",
  "@love.com",
  "@ygm.com",
  "@games.com",
  "@wow.com",
  "@outlook.com",
  "outlook.in",
  "@icloud.com",
];

export const HIDE_ON_PATH = [
  "/",
  "/public-job-detail/",
  "/public-full-job-detail/",
  "/company-public-profile/",
  "/not-authorized",
  "/open-jobs",
  "/job-detail/",
  "/company-post-job",
];

// export function copyTextToClipboard(text) {
//   let inputElement = document.createElement("input");
//   inputElement.setAttribute("value", text);
//   document.body.appendChild(inputElement);
//   inputElement.select();
//   document.execCommand("copy");
//   inputElement.parentNode.removeChild(inputElement);
// }

export function handleCopyClick(copyText, type = "", alert) {
  let inputElement = document.createElement("input");
  inputElement.setAttribute("value", copyText);
  document.body.appendChild(inputElement);
  inputElement.select();
  document.execCommand("copy");
  inputElement.parentNode.removeChild(inputElement);

  // copyTextToClipboard(copyText);
  alert?.success(type + " Copied ");
}

export const truncate = (input) =>
  input && input?.length > 5 ? `${input.substring(0, 10)}...` : input;
export const truncateLarge = (input) =>
  input && input?.length > 5 ? `${input.substring(0, 30)}...` : input;
export const truncateQuestion = (input) =>
  input && input?.length > 159 ? `${input.substring(0, 160)}...` : input;
export const truncateWithRange = (input, range) =>
  input && input?.length > 5 ? `${input.substring(0, range)}...` : input;

export const thousandsSeparators = (num) => {
  const curr = parseInt(num).toLocaleString("en-IN", {
    maximumFractionDigits: 0,
    style: "currency",
    currency: "INR",
  });
  // return Number.parseInt(curr).toFixed(0)
  return curr;
};

// export function renderMultilineText(text) {
//   const list = text.split("\n");
//   console.log("list", list);
//   return (
//     // <ul>
//     list.map((line, index) => <p key={index}>{line}</p>)
//     // </ul>
//   );
// }
