import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { addDomainDetail } from "../Redux/domainDetailSlice";

function useOnPageChange() {
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState("");
  const dispatch = useDispatch();
  const domainDetail = useSelector((state) => state?.domainDetail?.data);
  const resetTheme = () => {
    const obj1 = {
      ...domainDetail,
      primary_color: domainDetail?.primaryColorMain,
      secondary_color: domainDetail?.secondaryColorMain,
    };

    dispatch(addDomainDetail({ domainDetailData: obj1 }));
  };

  useEffect(() => {
    if (lastLocation?.pathname === "/company-profile") {
      // call your API
      resetTheme();
    }

    setLastLocation(location);
  }, [location]);
}
export default useOnPageChange;
