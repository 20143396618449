import {
  Badge,
  Tooltip,
  tooltipClasses,
  IconButton,
  Box,
  styled,
  Typography,
  Button,
  Menu,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { NotificationSwitch } from "./ToggleSwitch";
import AllNotificationCards from "./AllNotificationCards";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  updateNotificationRead,
  updateShowUnread,
} from "../../../Redux/notifcationSlice";
import fetchApi from "../../../Apis/fetchApi";
import { SANDESH_API } from "../../../Constants/constants";
import { ReactComponent as NoData } from "../../../Assets//Images/svg/noNotifications.svg"; //../../Assets//Images/svg/noNotifications.svg

const NotificationTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
    "&:before": {
      boxShadow: "0px 0px 4px 0px #00000040",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px 0px #00000040",
    width: "380px",
    borderRadius: "8px",
  },
}));

function NotificationBell() {
  // const [open, setOpen] = React.useState(false);

  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //   setOpen(true);
  // };
  const theme = useTheme();
  const dispatch = useDispatch();
  const notificationDetails = useSelector(
    (state) => state?.notification?.notificationDetails
  );
  const firstRead = useSelector((state) => state?.notification?.firstRead);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const showUnread = useSelector((state) => state?.notification?.showUnread);
  const [showUnreadBell, setShowUnreadBell] = useState(true);

  const [showData, setShowData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    //console.log("open", open);
    if (open) {
      if (firstRead) {
        dispatch(updateNotificationRead({ firstRead: false }));
      } else if (!firstRead) {
        dispatch(getNotifications());
      }
    }
  }, [open]);

  const unreadHelper = (data) => {
    setShowData(data?.data?.results);
    setResponse(data?.data);
  };
  const getUnreadNotifications = () => {
    fetchApi({
      url: `${SANDESH_API}api/company/get-notifications/?is_read=false`,
      method: "get",
      //alert: alert,
      setLoading: setLoading,
      setResponse: unreadHelper,
    });
  };
  useEffect(() => {
    //console.log("notificationDetails", notificationDetails);
    if (open && showUnread) {
      setShowData([]);
      setResponse(null);
      getUnreadNotifications();
    } else if (open && notificationDetails) {
      setShowData(notificationDetails?.results);
      setResponse(notificationDetails);
    }
  }, [open, showUnread, notificationDetails]);

  return (
    <>
      {/* <NotificationTooltip
        title={
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">All Notifications (45)</Typography>
              <NotificationSwitch />
            </div>

            <Box>
              <AllNotificationCards />
              <AllNotificationCards /> <AllNotificationCards />
            </Box>

            <Button
              variant="text"
              color="primary"
              sx={{ p: 0, fontSize: "14px", ml: 0.5, mb: 2 }}
            >
              View all Notifications
            </Button>
          </div>
        }
  
      > */}
      <Tooltip title={"Notifications"} placement="bottom">
        <IconButton
          onClick={handleClick}
          sx={{ color: "#000" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {/* <Badge color="secondary" badgeContent={2}> */}
          <NotificationsNoneOutlinedIcon />
          {/* </Badge> */}
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2.5,
            borderRadius: "8px",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 124,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <div
          style={{ padding: "10px", paddingBottom: "0px", paddingTop: "0px" }}
        >
          {notificationDetails?.loading ? (
            <div>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  maxHeight: "100px",
                  minWidth: "300px",
                  overFlowY: "scroll",
                }}
              >
                <Typography variant="body2">
                  All Notifications ({response?.count ? response?.count : 0})
                </Typography>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ fontSize: "10px" }}>only show unread</span>
                  <NotificationSwitch
                    value={showUnread}
                    checked={showUnread}
                    onChange={() => {
                      dispatch(updateShowUnread({ showUnread: !showUnread }));
                    }}
                  />
                </div>
              </div>

              <Box sx={{ mt: -1 }}>
                {showData?.length > 0 &&
                  showData?.map((notifyObj) => (
                    <AllNotificationCards data={notifyObj} />
                  ))}
              </Box>
              {/* <Box sx={{ mt: -1 }}>
                {notificationDetails &&
                  notificationDetails?.results?.map((notifyObj) => (
                    <AllNotificationCards data={notifyObj} />
                  ))}
              </Box> */}
              {!response?.previous && !response?.next && !loading ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "50px",
                      marginBottom: "50px",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <NoData
                        height="80px"
                        width="80px"
                        alt="404 Error"
                        fill={theme.palette.primary.main}
                      />
                      <Typography variant="h6" align="center">
                        {`No Notification`}
                      </Typography>
                      {/* <Typography
                        variant="body2"
                        align="center"
                        sx={{ mt: 2, mb: 1 }}
                      >
                        {`we will notify you when something arrives`}
                      </Typography> */}
                    </div>
                  </div>
                </>
              ) : (
                loading && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <CircularProgress color="primary" />
                    </Box>
                  </Box>
                )
              )}

              {showData?.length > 0 && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    history.push("/all-notification");
                  }}
                  sx={{ p: 0, fontSize: "14px", ml: 0.5, mb: 1 }}
                >
                  View all Notifications
                </Button>
              )}
            </>
          )}
        </div>
      </Menu>
      {/* </NotificationTooltip> */}
    </>
  );
}

export default NotificationBell;
