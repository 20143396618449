import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Paper } from "@mui/material";

export const CustomAutoCompletePaper = (props) => {
  return (
    <Paper
      {...props}
      sx={{
        mt: 0.5,
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "8px",
      }}
    />
  );
};

export const CustomTextField = styled(TextField)({
  "& label": {
    color: "#6C757D",
    fontSize: "14px",
    paddingTop: "1.2px",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    height: "40px",
    "& fieldset": {
      borderRadius: "8px",
      color: "#7e7e7e",
    },

    "&.Mui-focused fieldset": {
      fontSize: "14px",
    },
    "&:hover fieldset": {
      fontSize: "14px",
    },
  },
});

export const CustomTextAreaField = styled(TextField)({
  "& label": {
    color: "#6C757D",
    fontSize: "14px",
    paddingTop: "1.2px",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    //height: "40px",
    "& fieldset": {
      borderRadius: "8px",
      color: "#7e7e7e",
    },

    "&.Mui-focused fieldset": {
      fontSize: "14px",
    },
    "&:hover fieldset": {
      fontSize: "14px",
    },
  },
});

export const CustomActionTextField = styled(TextField)({
  "& label": {
    color: "#6C757D",
    fontSize: "14px",
    paddingTop: "1.2px",
    backgroundColor: "#fff",
  },
  "& label.Mui-focused": {
    backgroundColor: "#fff",
    color: "#6C757D",
  },

  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    height: "40px",

    "& fieldset": {
      borderRadius: "8px",
      color: "#7e7e7e",
      border: "none",
      boxShadow: "0px 0px 4px 0px #00000040",
    },

    "&.Mui-focused fieldset": {
      fontSize: "14px",
    },
    "&:hover fieldset": {
      fontSize: "14px",
    },
  },
});

export const CustomActionChipTextField = styled(TextField)({
  "& label": {
    color: "#6C757D",
    fontSize: "14px",
    paddingTop: "1.2px",
    backgroundColor: "#fff",
  },
  "& label.Mui-focused": {
    backgroundColor: "#fff",
    color: "#6C757D",
  },

  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    minHeight: "40px",
    "& fieldset": {
      borderRadius: "8px",
      color: "#7e7e7e",
      border: "1px solid #B0B6BA",
    },

    "&.Mui-focused fieldset": {
      fontSize: "14px",
    },
    "&:hover fieldset": {
      fontSize: "14px",
    },
  },
});
