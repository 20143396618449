import * as React from "react";
import {
  Button,
  Paper,
  Autocomplete,
  TextField,
  InputBase,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import { PRAVESH_API } from "../../../Constants/constants";
import fetchApi from "../../../Apis/fetchApi";
import { useSelector } from "react-redux";
import { CustomTextField, CustomAutoCompletePaper } from "./InputField";

export default function Search({}) {
  const filter = createFilterOptions();
  const history = useHistory();
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const userDetails = useSelector(
    (state) => state?.userDetails?.userDetails?.user_details
  );
  const [searchSelected, setSearchSelected] = React.useState(null);

  const [navbarGlobalSearchLoading, setNavbarGlobalSearchLoading] =
    React.useState(false);

  const [navbarGlobalSearchData, setNavbarGlobalSearchData] = React.useState(
    []
  );
  const [searchQuery, setSearchQuery] = React.useState("");
  const navbarGlobalSearchHelper = (data) => {
    //console.log("NAVBAR", data?.data);
    setNavbarGlobalSearchData(data?.data);
  };
  const getNavbarGlobalSearch = () => {
    fetchApi({
      url: `${PRAVESH_API}company_saas/navbar/search/?company_id=${userDetails?.company}&search_term=${searchQuery}`,
      setResponse: navbarGlobalSearchHelper,
      setLoading: setNavbarGlobalSearchLoading,
      alert: alert,
    });
  };

  React.useEffect(() => {
    if (searchQuery && userDetails?.company) {
      getNavbarGlobalSearch();
    }
  }, [searchQuery]);

  const debounce = (func) => {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (e) => {
    setNavbarGlobalSearchData([]);
    setSearchQuery(e.target.value);
  };

  const optimisedHandleChange = React.useCallback(debounce(handleChange), []);

  React.useEffect(() => {
    if (searchQuery) {
      setOpenAutocomplete(true);
    } else {
      setOpenAutocomplete(false);
    }
  }, [searchQuery]);

  return (
    <Paper
      component="form"
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        boxShadow: "none",
        border: "1px solid #E2E6EA",
        minWidth: "340px",
        height: "40px",
        justifyContent: "space-between",
        borderRadius: "8px",
      }}
    >
      <div style={{ width: "80%", display: "grid" }}>
        <Autocomplete
          sx={{
            display: "inline-block",
            "& input": {
              //width: 200,
              bgcolor: "background.paper",
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.background.paper),
            },
          }}
          open={openAutocomplete}
          noOptionsText={"No Results Found"}
          PaperComponent={CustomAutoCompletePaper}
          loading={navbarGlobalSearchLoading}
          value={searchSelected}
          onChange={(event, newValue) => {
            // if (typeof newValue === "string") {
            //   setSearchQuery(newValue);
            // } else if (newValue) {
            history.push(newValue?.end_point);
            setSearchSelected(newValue);
            setOpenAutocomplete(false);
            // }
          }}
          selectOnFocus
          clearOnBlur
          onClose={() => {
            setOpenAutocomplete(false);
          }}
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={navbarGlobalSearchData ? navbarGlobalSearchData : []}
          getOptionLabel={(option) => {
            return `${option.text} | ${option.type}`;
            // return option.text;
            // return (
            //   <div
            //     style={{
            //       display: "flex",
            //       justifyContent: "space-between",
            //       width: "100%",
            //     }}
            //   >
            //     <span>{`${option.text}`}</span>
            //     <span style={{ color: "#b0b7c3" }}>{`${option.type}`}</span>
            //   </div>
            // );
          }}
          // freeSolo
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <InputBase
                {...params}
                inputProps={{
                  ...params.inputProps,

                  style: {
                    border: "none",
                    marginLeft: "10px",
                    minWidth: "100%",
                  },
                }}
                //sx={{ border: "none", marginLeft: "10px", width: "100%" }}
                margin="normal"
                variant="outlined"
                onKeyDown={(e) => {
                  if(e.key === 'Enter'){

                    e.preventDefault();
                  } 
                }}
                placeholder="Search Candidate, Job, Page …"
                onChange={(ev) => {
                  // dont fire API if the user delete or not entered anything
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    optimisedHandleChange(ev);
                  }
                }}
              />
            </div>
          )}
        />
      </div>
      <div style={{ width: "18%", position: "absolute", right: "0" }}>
        <Button
          color="primary"
          startIcon={<SearchIcon />}
          variant="contained"
          sx={{
            display: "flex",
            borderRadius: "8px",
            height: "40px",
            fontSize: "14px",
            marginRight: "-1px",
            // marginLeft: "-63px",
            boxShadow: "none",
          }}
          aria-label="directions"
          onClick={() => {
            if (searchQuery) getNavbarGlobalSearch();
          }}
        >
          Search
        </Button>
      </div>
    </Paper>
  );
}

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
];

const options = ["Option 1", "Option 2"];
