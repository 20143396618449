import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { eraseCookie, getCookie, SANDESH_API } from "../Constants/constants";
export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async () => {
    const url = `${SANDESH_API}api/company/get-notifications/`;
    const res = await axios.get(url, {
      //headers: { Authorization: `Token ${getCookie("user_token_test")}` },
      withCredentials: true,
    });
    return res.data.data;
  }
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notificationDetails: {},
    firstRead: true,
    showUnread: false,
    loading: false,
  },
  reducers: {
    logoutNotifications(state, action) {
      state.notificationDetails = {};
      state.loading = false;
    },
    updateNotificationRead: (state, action) => {
      state.firstRead = action?.payload?.firstRead;
    },
    updateShowUnread: (state, action) => {
      state.showUnread = action?.payload?.showUnread;
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state, action) => {
      state.loading = true;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      state.notificationDetails = action?.payload;
    },
    [getNotifications.rejected]: (state, action) => {
      state.loading = false;
      // eraseCookie("user_id");
      // eraseCookie("user_token_test");
      // eraseCookie("user_token");
      // localStorage.removeItem("loggedIn");
      //   window.location.href = "/";
    },
  },
});

export const { logoutNotifications, updateNotificationRead, updateShowUnread } =
  notificationSlice.actions;
export default notificationSlice.reducer;
