import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchIcon from "@mui/icons-material/Search";
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PieChartIcon from "@mui/icons-material/PieChart";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
export const IconsCollection = {
  DASH: <DashboardIcon />,
  AI_JOBS: <AutoFixHighIcon />,
  EXP_X: <SearchIcon />,
  JOBS: <WorkIcon />,
  RELATION: <PeopleIcon />,
  MEET: <RecordVoiceOverIcon />,
  CANDIDATE: <ContactsOutlinedIcon />,
  REPRT: <PieChartIcon />,
  PLANS: <ReceiptLongIcon />,
  SET: <SettingsIcon />,
  AI_JOBS: <AutoAwesomeIcon />,
  LOCKED: <LockOutlinedIcon sx={{ fontSize: 16, marginLeft: "5px" }} />,
};
