import { createSlice } from "@reduxjs/toolkit";

const profileUnlockSlice = createSlice({
  name: "profileUnlockInfo",
  initialState: {
    data: {},
  },
  reducers: {
    addUnlockData(state, action) {
      state.data = action?.payload?.unlockData;
    },

    logoutProfileUnlockData(state, action) {
      state.creditDetails = {};
    },
  },
});

export const { addUnlockData, logoutProfileUnlockData } =
  profileUnlockSlice.actions;
export default profileUnlockSlice.reducer;
