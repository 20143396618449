import React from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Button, Typography } from "@mui/material";

export default function ConfirmPassword({ setStep, fromPublicJobApply }) {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <TaskAltIcon sx={{ color: "#34A853", fontSize: "45px" }} />

        <Typography
          variant="body1"
          sx={{
            fontWeight: "500",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "30px",
          }}
        >
          Password Changed Successfully !
        </Typography>
        <Button
          color="primary"
          variant="contained"
          sx={{
            boxShadow: "none",
            height: "40px",
            display: "flex",
            borderRadius: "8px",
            fontWeight: "400",
            width: "100%",
            fontSize: "15px",
          }}
          onClick={() => {
            fromPublicJobApply ? setStep(4) : setStep(2);
          }}
        >
          Login
        </Button>
      </div>
    </>
  );
}
