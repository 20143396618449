import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Typography,
  useTheme,
  alpha,
  Button,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import Axios from "axios";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { useAlert } from "react-alert";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Home, WorkHistoryOutlined } from "@mui/icons-material";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";

const ListItemChip = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

// function valueLabelFormat(value) {
//   var new_value;
//   if (value > 99999) {
//     // if (value > 999999) new_value = MFormatter(value);
//     // else new_value = LFormatter(value);
//     new_value = LFormatter(value);
//   } else new_value = kFormatter(value);
//   return new_value;
// }

export default function JobCard({ data }) {
  const alert = useAlert();
  const history = useHistory();
  const theme = useTheme();

  const paper = {
    minHeight: "420px",
    minWidth: "300px",
    padding: theme.spacing(0.5),
    margin: "auto",
    backgroundColor: "#fff",
    border: "1px solid #E3E6EA",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      minHeight: "420px",
      backgroundColor: "#fff",
      // border: "none",
      // padding: 0,
    },
    [theme.breakpoints.only("xs")]: {
      minHeight: "420px",
      backgroundColor: "#fff",
      border: "none",
      padding: 0,
    },
  };

  const grid = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      justifyContent: "center",
    },
  };
  const info = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  };
  const infoitem = {
    display: "flex",
    flexDirection: "row",
    alignContent: "space-around",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: "10px",
  };

  const info_icon = {
    color: "#6c757d",
    height: "18px",
  };
  const detail = {
    display: "flex",
    flexWrap: "wrap",
    // flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
    alignContent: "center",
  };
  const location = {
    color: "#6c757d",
    marginTop: "5px",
  };
  const skill = {
    marginTop: "15px",
    // color: "#6c757d",
  };
  const btn = {
    marginTop: "15px",
  };
  const text = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      alignItems: "center",
    },
  };
  const subtext = {
    width: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
  };
  const title = {
    display: "flex",
    marginTop: "5px",
    marginBottom: "5px",
    flexDirection: "row",
    alignItems: "center",
  };
  const chip = {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    maxWidth: "90px",
    // border:"1px solid #007bff"
  };
  const chipsection = {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    listStyle: "none",
    //  display: "flex",
    "& > *": {
      margin: theme.spacing(0.7),
      marginLeft: "0px",
    },
  };
  const check = {
    backgroundColor: "#58aaff",
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "none",
  };

  const imggrid = {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  };
  function LFormatter(num) {
    return Math.abs(num) > 99999
      ? Math.sign(num) * (Math.abs(num) / 100000).toFixed(1) + "L"
      : Math.sign(num) * Math.abs(num);
  }

  //   useEffect(() => {
  //     if (applyClicked && data) {
  //       setLoading(true);
  //       Axios.post(
  //         `${NIYUKTI_API}job/student/apply/`,
  //         {
  //           job_id: parseInt(data.id),
  //           user_id: localStorage.getItem("user_id"),
  //           round: 1,
  //           status: 1,
  //           feedback: 1,
  //           college_id: 0,
  //           profile_complete_percentage: localStorage.getItem("profile_complete"),
  //         },
  //         {
  //           headers: {
  //             Authorization: "Token " + localStorage.getItem("gw_token"),
  //           },
  //         }
  //       )
  //         .then((res) => {
  //           if (res.data.success) {
  //             router.push(
  //               { query: { job_id: data.id.toString(), applied: true } },
  //               undefined,
  //               {
  //                 shallow: true,
  //               }
  //             );
  //             alert.success(res.data.data.message);
  //             setIsApplied(true);
  //             reloadValue.setReloadCard(true);
  //           } else {
  //             alert.error(res.data.error);
  //           }
  //           setLoading(false);
  //           setApplyClicked(false);
  //         })
  //         .catch((err) => {
  //           alert.error("Some error occurred");
  //           setApplyClicked(false);
  //           setLoading(false);
  //         });
  //     }
  //   }, [applyClicked]);

  //   const handleJobApply = () => {
  //     if (
  //       localStorage.getItem("user_type") === "Company" ||
  //       localStorage.getItem("user_type") === "College"
  //     ) {
  //       alert.error("Please log In With Job Seeker Account");
  //       return;
  //     }

  //     if (localStorage.getItem("user_id")) {
  //       if (localStorage.getItem("is_student_resume_uploaded") === "true") {
  //         setApplyClicked(true);
  //       } else {
  //         setToGo(3);
  //         setOpen(true);
  //       }
  //     } else {
  //       setOpen(true);
  //     }
  //   };

  const mainData = [
    {
      icon: <PlayCircleFilledWhiteOutlinedIcon style={info_icon} />,
      heading: "Start Date",
      details: data?.start_date_formatted ? data?.start_date_formatted : "NA",
      show: true,
    },

    {
      icon: <PaymentsOutlinedIcon style={info_icon} />,
      heading: "Salary",
      details: `${LFormatter(data?.ctc_min)} - ${LFormatter(data?.ctc_max)}`,
      show: true,
      //   details:
      //     data?.salary_type === "PAID"
      //       ? data?.ctc_min !== 0 &&
      //         data?.ctc_max !== 0 &&
      //         valueLabelFormat(data?.ctc_min) +
      //           " - " +
      //           valueLabelFormat(data?.ctc_max) +
      //           " " +
      //           data?.salary_payment_type
      //       : data?.salary_type === "NOT DISCLOSED"
      //       ? "Not Disclosed"
      //       : data?.salary_type === "UNPAID" && "Unpaid",
      //   show: data?.job_type !== 1 ? true : false,
    },

    {
      icon: <WorkOutlineOutlinedIcon style={info_icon} />,
      heading: "Job Type",
      details: data?.job_type,
      show: true,
      //   details: data?.job_type_name,
      //   show: data?.job_type ? true : false,
    },

    {
      icon: <DateRangeIcon style={info_icon} />,
      heading: "Duration",
      details: data?.duration ? data?.duration : "NA",
      show: true,
      //   details:
      //     monthDiff(
      //       new Date(data?.job_duration_start),
      //       new Date(data?.job_duration_end)
      //     ) + " months",
      //   show: data?.job_duration_start && data?.job_duration_end ? true : false,
    },

    {
      icon: <HourglassBottomOutlinedIcon style={info_icon} />,
      heading: "Apply By",
      details: data?.apply_by_formatted,
      show: true,
      //   details: data?.eligibility_criteria?.experience[0],
      //   show: data?.job_type === 7 ? true : false,
    },
  ];

  const [showMoreLocation, setShowMoreLocation] = useState(false);
  const [showMoreSkills, setShowMoreSkills] = useState(false);

  //   let pid = data.job_title.replace(/\s/g, "-");
  //   pid += "-" + data.id.toString();
  // window.open(`/job-details/${getDetailPageLink(data?.job_title, data?.id)}/`);
  // x

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "React.js" },
    { key: 1, label: "HTML" },
  ]);

  return (
    <>
      <div
        style={paper}
        onClick={() => {
          window.open(`/public-full-job-detail/?id=${data.id}`);
        }}
      >
        <div style={{ cursor: "pointer" }}>
          <Grid container spacing={3}>
            <Grid md={12}>
              {" "}
              {/* <div style={text}> */}
              <Typography
                // noWrap
                // style={subtext}
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                {data?.job_title}
              </Typography>
              {/* </div> */}
            </Grid>
            <Grid
              item
              xs={12}
              container
              // style={info}
              direction="row"
              style={{ display: "flex", justifyContent: "flex-start" }}
              spacing={1}
            >
              <Container
                style={{
                  // cursor: "pointer",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                {mainData?.map(
                  (item) =>
                    item.show && (
                      <Grid item xs={12} md={12} style={{ marginLeft: "-8px" }}>
                        <div style={infoitem}>
                          {item.icon}
                          <div style={detail}>
                            <Typography
                              color="textSecondary"
                              style={{ fontWeight: 600, fontSize: "13px" }}
                            >
                              {item.heading} :
                            </Typography>
                            <Typography
                              noWrap
                              variant="body2"
                              color="textSecondary"
                              style={{ marginLeft: "5px", maxWidth: "200px" }}
                            >
                              {item.details}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    )
                )}
              </Container>
            </Grid>
            <Grid md={12} sx={{ pt: 0, pb: 0, marginTop: "-3px" }}>
              <div style={location}>
                <Box style={{ display: "flex", marginLeft: "-4px" }}>
                  <RoomOutlinedIcon />
                  <Typography
                    color="textSecondary"
                    style={{
                      paddingBottom: "10px",
                      fontWeight: 600,
                      fontSize: "13px",
                    }}
                  >
                    {`Location: `}
                  </Typography>
                </Box>
              </div>
              {!data?.location?.length > 0 && (
                <Box>
                  <Chip style={chip} size="small" label={"WFH"} />
                </Box>
              )}
              <div component="ul" style={chipsection}>
                <Grid md={12} sx={{ pt: 0, pb: 0, p: 0, marginTop: "-3px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      listStyle: "none",
                      p: 0.5,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {data?.location?.length > 0 &&
                      !showMoreLocation &&
                      data?.location?.slice(0, 2)?.map((data1, i) => (
                        <li key={i}>
                          <Chip style={chip} size="small" label={data1?.city} />
                        </li>
                      ))}
                    {data?.location?.length > 2 && !showMoreLocation && (
                      <Typography
                        color="primary"
                        variant="body2"
                        style={{ cursor: "pointer", marginTop: "7px" }}
                        onClick={() => {
                          setShowMoreLocation(!showMoreLocation);
                        }}
                      >
                        {`+${data?.location?.length - 2} More`}
                      </Typography>
                    )}

                    {data?.location?.length > 0 &&
                      showMoreLocation &&
                      data?.location?.map((data1, i) => (
                        <li key={i}>
                          <Chip style={chip} size="small" label={data1?.city} />
                        </li>
                      ))}
                    {data?.location?.length > 2 && showMoreLocation && (
                      <Typography
                        color="primary"
                        variant="body2"
                        style={{
                          cursor: "pointer",
                          marginTop: "7px",
                          marginLeft: "5px",
                        }}
                        onClick={() => {
                          setShowMoreLocation(!showMoreLocation);
                        }}
                      >
                        {`Show Less`}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </div>
            </Grid>

            <Grid md={12} sx={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "8px",
                  height: "40px",
                  boxShadow: "none",
                }}
                onClick={() => {
                  window.open(`/public-full-job-detail/?id=${data.id}`);
                }}
              >
                Apply Now
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
